import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import ReactPlayerLoader from '@brightcove/react-player-loader'
import { useEventListener } from './hooks'
import { Card, Button, IconButton, CircularProgress } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
// import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import * as workerTimers from 'worker-timers'
import { useTranslation } from 'react-i18next';

const PREFIX = 'BrightcoveVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  customOverlay: `${PREFIX}-customOverlay`,
  resume: `${PREFIX}-resume`,
  fullscreenButton: `${PREFIX}-fullscreenButton`,
  video: `${PREFIX}-video`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ aspectRatio, wmOpacity }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  maxWidth: aspectRatio ? `${1 / aspectRatio * 100}vh` : 0,
    maxHeight: aspectRatio ? `${aspectRatio * 100}vw` : 0,
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.customOverlay}`]: {
    // color: 'white !important',
    backgroundColor: 'transparent !important',
    height: `100% !important`,
    width: `100% !important`,
    marginTop: 'auto !important',
    marginBottom: 'auto !important',
    padding: '0 !important',
    left: '0 !important',
    marginLeft: '0 !important',
    // display: 'flex !important',
    display: 'block !important',
    // alignItems: 'center !important',
    // justifyContent: 'center !important',
    opacity: (wmOpacity && `${wmOpacity / 100} !important`) || '0.15 !important',
    // fontSize: 'calc(9px + (140 - 9) * ((100vw - 150px) / (2400 - 150))) !important',
    fontSize: '1em',
    userSelect: 'none !important',
    MozUserSelect: 'none !important',
    WebkitUserSelect: 'none !important',
    msUserSelect: 'none !important',
    // transform: 'translateY(-50%) rotate(-20.5deg) !important',
    // pointerEvents: 'none !important'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  },
  [`& .${classes.fullscreenButton}`]: {
    // position: 'absolute',
    // bottom: 0,
    // right: 0,
    color: 'white',
    zIndex: 2,
    opacity: 0.25,
    '&:hover': {
      opacity: 1
    },
    mixBlendMode: 'difference'
  },
  [`& .${classes.video}`]: {
    maxWidth: aspectRatio ? `${1 / aspectRatio * 100}vh` : 0,
    maxHeight: aspectRatio ? `${aspectRatio * 100}vw` : 0,
    width: aspectRatio ? '100%' : 0,
    height: aspectRatio ? '100%' : 0,
    flex: 1
  }
}));

// document.pictureInPictureEnabled = false

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const overlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%' viewBox='0 0 200 100'>    
      <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
              <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
              <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
              <feGaussianBlur in="map" stdDeviation="0.15" />
      </filter>
      <text x="50%" y="50%" transform="rotate(-${Math.atan(height / width) * 180 / Math.PI}, 100, 50)" filter="url(#filter)" textLength="200" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
    </svg>
  </svg>`
}

const tiledOverlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%'>
      <defs>
        <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
          <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
          <feGaussianBlur in="map" stdDeviation="${height / 2000} ${width / 2000}" />
        </filter>
        <pattern id="p1" height='25%' width='50%' style="font-size: ${height / 22}px" patternUnits="userSpaceOnUse" patternTransform="rotate(-18.43494882292201)">    
          <text x="25%" y="12.5%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
        </pattern>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#p1)" filter="url(#filter)"></rect>
    </svg>
  </svg>`
}

const BrightcoveVideoPlayer = React.memo(({ videoRef, fluid, fill, fullscreen, allowFullscreen, isFullscreen, toggleFullScreen, fadeOut, autoStop, videoStopTime, techCheck, wm, wmOpacity, watermarkStyle, playerId, videoId, multiSection, videoIndex = 0, sessionId, alreadyStarted, videoTime, sessionStartTime, videoDuration, unloadTime, onStarted, onPlay, onResume, onBuffer, onTimeUpdate, onUnload, onMetadataLoaded, onVideoLoaded, onVisiblityChange, onFinished, onVideoError, hasOnScreenDial }) => {

  const [videoLoaded, setVideoLoaded] = useState(false)
  const [resuming, setResuming] = useState(true)
  const [aspectRatio, setAspectRatio] = useState()
  const { t } = useTranslation('video_player')



  const visibilityChangeFn = () => { }

  const playerRef = useRef()
  const indexRef = useRef(videoIndex)
  const timerRef = useRef()
  const stopTimeRef = useRef(videoStopTime)
  // const fadeRef = useRef()
  // const volumeRef = useRef(100)

  useEffect(() => {
    stopTimeRef.current = videoStopTime
  }, [videoStopTime])

  useEffect(() => {
    console.log('VIDEO INDEX IS....', videoIndex)
    indexRef.current = videoIndex
    if (!!playerRef.current?.playlist()?.length) playerRef.current.playlist.currentItem(videoIndex)
  }, [videoIndex])

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  // if (onVisiblityChange) {
  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);
  // }

  const overlayFn = useMemo(() => {
    return watermarkStyle === 'Tiled' ? tiledOverlay : overlay
  }, [watermarkStyle])

  const handleResume = useCallback(() => {
    const now = Date.now()
    // if (videoTime && unloadTime) {
    //   if (onFinished && (videoTime + (now - unloadTime)) >= ((videoStopTime * 1000) || videoDuration)) {
    //     onFinished()
    //   } else {
    //     playerRef.current.currentTime(videoTime + (now - unloadTime) / 1000)
    //   }
    // } else {
    //   if (onFinished && ((now - sessionStartTime)) >= ((videoStopTime * 1000) || videoDuration)) {
    //     onFinished()
    //   } else {
    //     playerRef.current.currentTime((now - sessionStartTime) / 1000)
    //   }
    // }
    if ((sessionStartTime && onFinished) && ((now - sessionStartTime)) >= ((stopTimeRef.current * 1000) || videoDuration)) {
      onFinished(indexRef.current)
    } else {
      console.log('RESUMING CONTENT', now, sessionStartTime, (now - sessionStartTime) / 1000)
      playerRef.current.currentTime((now - sessionStartTime) / 1000)
      // if (allowFullscreen && !isFullscreen) toggleFullScreen()
    }
    playerRef.current.play()
    setResuming(false)
  }, [onFinished, sessionStartTime, videoDuration])

  useEffect(() => {
    const now = Date.now()
    // if (sessionStartTime) {
    //   console.log('COMPLETE CHECK', sessionStartTime, videoStopTime, videoDuration, videoTime, unloadTime)
    //   if (videoTime && unloadTime) {
    //     if (onFinished && (videoTime + (now - unloadTime)) >= ((videoStopTime * 1000) || videoDuration)) {
    //       onFinished()
    //     }
    //   } else {
    //     if (onFinished && ((now - sessionStartTime)) >= ((videoStopTime * 1000) || videoDuration)) {
    //       onFinished()
    //     }
    //   }
    // }
    if (!techCheck && (sessionStartTime && onFinished) && ((now - sessionStartTime)) >= ((stopTimeRef.current * 1000) || videoDuration)) {
      onFinished(indexRef.current)
    }
  }, [onFinished, sessionStartTime, videoDuration, techCheck])

  useEffect(() => {
    if (fadeOut && playerRef.current) {
      timerRef.current = workerTimers.setInterval(() => {
        const currentVolume = playerRef.current.volume()
        const newVolume = currentVolume - 0.05
        currentVolume > 0 ? playerRef.current.volume(newVolume <= 0 ? 0 : newVolume) : workerTimers.clearInterval(timerRef.current)
      }, 200)
    }
  }, [fadeOut])

  const afterLoaded = useCallback((player) => {
    playerRef.current = player.ref
    console.log(playerRef.current)
    if (videoRef) videoRef.current = player.ref //to get time data

    playerRef.current.tech_.el_.disablePictureInPicture = true
    playerRef.current.tech_.el_.controls = false
    playerRef.current.tech_.el_.blur();
    playerRef.current.tech_.el_.addEventListener('focus', (e) => {
      e.currentTarget.blur()
      console.log('video focus')
    })

    playerRef.current.el_.blur();
    playerRef.current.el_.addEventListener('focus', (e) => {
      e.currentTarget.blur()
      console.log('brightcove focus')
    })

    playerRef.current.controls(false);
    if (fullscreen) playerRef.current.enterFullWindow();
    playerRef.current.autoplay(false)
    playerRef.current.muted(false)

    //INITAL BLANK OVERLAy
    playerRef.current.overlay({
      content: '',
      class: classes.customOverlay,
      overlays: [{
        align: "center",
        content: "",
        start: 'play',
        end: 'ended'
      }]
    });

    // playerRef.current.overlay({
    //   content: '',
    //   class: classes.customOverlay,
    //   overlays: [{
    //     align: "center",
    //     content: overlay(wm, Math.floor(Math.random() * 10000000)),
    //     start: 'play',
    //     end: 'ended'
    //   }]
    // });

    // GUEST PLAYER
    if (!alreadyStarted) playerRef.current.play()

    // ADMIN VIEWER
    // if (alreadyStarted) {
    //   playerRef.current.currentTime((Date.now() - sessionStartTime) / 1000)
    // }

    playerRef.current.on('play', (event) => {
      console.log('play event')
      if (onStarted) onStarted()
    })

    playerRef.current.on('pause', () => {
      if (!playerRef.current.ended()) playerRef.current.play()
      console.log('pause event')
    })

    playerRef.current.on('ended', () => {
      console.log('end event', indexRef.current)
      if (onFinished) onFinished(indexRef.current)
    })

    playerRef.current.on('timeupdate', (e) => {
      playerRef.current.overlay({
        content: '',
        class: classes.customOverlay,
        overlays: [{
          align: "center",
          content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
          start: 'play',
          end: 'ended'
        }]
      });
      // console.log(playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth)
      if (onTimeUpdate) onTimeUpdate(e)
      if (stopTimeRef.current && e.target.player?.currentTime() >= stopTimeRef.current && onFinished) onFinished(indexRef.current)
    })

    playerRef.current.on('seeked', () => {
      console.log('seeked event')
      // if (videoTime && !loadedRef.current) {
      //   console.log('FAST forwarding')
      //   console.log(videoTime, unloadTime, videoTime + (Date.now() - unloadTime) / 1000)
      //   playerRef.current.currentTime(videoTime + (Date.now() - unloadTime) / 1000)
      //   loadedRef.current = true
      // }
    })

    playerRef.current.on('error', (e) => {
      console.log('error event', e)
      if (onVideoError) onVideoError(playerRef.current.error())
    })

    playerRef.current.on('seeking', () => {
      console.log('seeking event')
    })

    playerRef.current.on('loadedmetadata', () => {
      console.log('loadedmetadata event', playerRef.current)
      if (!!playerRef.current.playlist()?.length) playerRef.current.playlist.currentItem(indexRef.current)
      playerRef.current.overlay({
        content: '',
        class: classes.customOverlay,
        overlays: [{
          align: "center",
          content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
          start: 'play',
          end: 'ended'
        }]
      });
      setAspectRatio(playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
      if (onMetadataLoaded) onMetadataLoaded(playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
    })

    playerRef.current.on('loadeddata', () => {
      console.log('loadeddata event')
      if (onVideoLoaded) onVideoLoaded()
      if (!alreadyStarted) setResuming(false)
      setVideoLoaded(true)
    })

    playerRef.current.on('playing', (event) => {
      console.log('playing event')
      if (onPlay) onPlay()
    })

    playerRef.current.on('durationchange', (e) => {
      console.log('durationchange event', e)
    })

    playerRef.current.on('waiting', () => {
      console.log('waiting event')
    })
  }, [alreadyStarted, fullscreen, videoRef, onFinished, onMetadataLoaded, onPlay, onStarted, onTimeUpdate, onVideoError, onVideoLoaded, classes.customOverlay, wm, overlayFn])

  return (
    (<Root aspectRatio={aspectRatio} wmOpacity={wmOpacity}>
      {resuming && <div className={classes.resume}>
        <div className={classes.continueBack}></div>
        <Card className={classes.continue} elevation={12}>
          {videoLoaded ? <>
            {videoTime && unloadTime ? t('click_to_resume') : t('click_to_join')}
            <Button className={classes.margin} disabled={!videoLoaded} color='primary' variant='contained' onClick={handleResume}>{videoTime && unloadTime ? t('resume') : t('join')}</Button>
          </> : <>
            {t('please_wait')}
            <CircularProgress className={classes.margin} />
          </>}
        </Card>
      </div>}
      <ReactPlayerLoader attrs={{ className: classes.video }} options={{ fluid, fill }} accountId='6121265898001' playerId={playerId} videoId={!multiSection && videoId} playlistId={multiSection && videoId} onSuccess={afterLoaded} />
      {(allowFullscreen && !isFullscreen) && <div style={{ position: 'absolute', maxHeight: '100vw', height: aspectRatio ? `calc(${100 * 1 / aspectRatio}%)` : '100vw', maxWidth: '100vh', width: aspectRatio ? `calc(${100 * aspectRatio}%)` : '100vh', transform: hasOnScreenDial ? 'translate(0,50%) rotate(270deg) translate(50%,0)' : 'rotate(270deg)' }}>
        <IconButton className={classes.fullscreenButton} color="inherit" onClick={toggleFullScreen}>
          {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
          <FullscreenIcon />
        </IconButton>
      </div>}
    </Root>)
  );
})

export default BrightcoveVideoPlayer;
