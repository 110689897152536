import * as React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { makeStyles, useTheme } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const PREFIX = 'LoadingStepIcon';

const classes = {
    root: `${PREFIX}-root`,
    text: `${PREFIX}-text`,
    active: `${PREFIX}-active`,
    completed: `${PREFIX}-completed`,
    error: `${PREFIX}-error`,
    spinnerBox: `${PREFIX}-spinnerBox`,
    spinner: `${PREFIX}-spinner`
};

export const Root = styled('div')(({ theme }) => ({
    display: 'block',
    color: theme.palette.text.disabled,
    fontSize: 'calc(6/5 * 3rem)',

    '&.completed': {
        color: theme.palette.success.main,
    },
    '&.active': {
        color: theme.palette.primary.main,
    },
    '&.error': {
        color: theme.palette.error.main,
    },

    // Styles applied to the SVG text element
    '& .text': {
        fill: theme.palette.primary.contrastText,
        fontSize: theme.typography.caption.fontSize,
        fontFamily: theme.typography.fontFamily,
    },

    [`& .${classes.spinnerBox}`]: {
        height: 'calc(6/5 * 3rem)',//'6rem',
        width: 'calc(6/5 * 3rem)',//'6rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    [`& .${classes.spinner}`]: {
        overflow: 'visible'
    }
}));

export { };

const LoadingStepIcon = React.forwardRef(function LoadingStepIcon(props, ref) {
    const { completed = false, active = false, error = false, } = props;
    const theme = useTheme();

    const className = clsx(classes.root, {
        [classes.active]: active,
        [classes.error]: error,
        [classes.completed]: completed,
    });

    if (error) {
        return <Root theme={theme}><WarningIcon className={className} ref={ref} /></Root>;
    }

    if (completed) {
        return <Root theme={theme}><CheckCircleOutlineIcon color={'success'} className={className} ref={ref} /></Root>;
    }

    if (active) {
        return (
            <Root theme={theme}>
                <div className={classes.spinnerBox}>
                    <CircularProgress size='3rem' thickness={4.32} className={className} ref={ref} classes={{ svg: classes.spinner }} />
                </div>
            </Root>
        );
    }

    return <Root theme={theme}><RadioButtonUncheckedIcon className={className} ref={ref} /></Root>;

});

export default (LoadingStepIcon);