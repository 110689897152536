
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Tooltip, AppBar } from '@mui/material';
import { getAllSessionFilesPromise, getSessionFilePromise } from './services/filesService';
import { FaDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';


const PREFIX = 'SessionFiles';

const classes = {
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    table: `${PREFIX}-table`,
    header: `${PREFIX}-header`,
    headerText: `${PREFIX}-headerText`,
    tableCell: `${PREFIX}-tableCell`,
    buttonRow: `${PREFIX}-buttonRow`,
    button: `${PREFIX}-button`,
    downloadButton: `${PREFIX}-downloadButton`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    [`& .${classes.modal}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw'
    },
    [`& .${classes.paper}`]: {
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        width: '80%',
        minWidth: '300px',
        // padding: '15px 0 0 0',
        padding: '1rem',
        maxHeight: '98%'
    },
    [`& .${classes.table}`]: {
        // margin: '1rem 0 1rem 0'
    },
    [`& .${classes.header}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        padding: '0 15px 0 15px',
        marginBottom: '6px'
    },
    [`& .${classes.headerText}`]: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(5rem + 5px)',
        alignItems: 'center',
        fontWeight: 800
    },
    [`& .${classes.tableCell}`]: {
        fontSize: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 2rem 0 2rem'
    },
    [`& .${classes.buttonRow}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0.5rem'
    },
    [`& .${classes.button}`]: {
        margin: '0 0.25rem 0 0.25rem'
    },
    [`& .${classes.downloadButton}`]: {
        padding: '5px',
        minWidth: '0px',
    }
});


export const SessionFiles = React.memo(({ sessionId, token, setSessionFileCount }) => {

    const [allFiles, setAllFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation('client_options');

    const getAllSessionFiles = useCallback(async () => {
        return await getAllSessionFilesPromise(sessionId, token);
    }, [sessionId, token]);

    const checkAllFiles = useCallback(async () => {
        setIsLoading(true);
        const data = await getAllSessionFiles();
        setAllFiles(data.map(i => {
            let key = i.Key.split('/').slice(2).join('/');
            let filename = i.Key.split('_').slice(2).join('_');
            return { key: key, name: filename }
        }));
        setIsLoading(false);
    }, [getAllSessionFiles])

    useEffect(() => {
        checkAllFiles();
    }, [checkAllFiles]);

    useEffect(() => {
        setSessionFileCount(allFiles.length < 5 ? allFiles.length : 5); // use to set marginTop of FilePopover. If allFiles > 5, popover will be scrollable
    }, [setSessionFileCount, allFiles])

    const getSessionFile = useCallback(async (filename) => {
        const data = await getSessionFilePromise(sessionId, filename, token);
        return data;
    }, [sessionId, token]);

    const downloadFile = useCallback((url, file) => {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': `application/octet-stream`,
            },
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.download = file.name;

                document.body.appendChild(link);

                link.click();

                link.parentNode.removeChild(link);
            })
    }, []);

    const tableRef = useRef();

    return (
        (<Root>
            <AppBar position="static">
                <Typography variant="h5" className={classes.headerText}>{t('shared_files_tooltip')}</Typography>
            </AppBar>
            <TableContainer className={classes.table}>
                <Table size="small" ref={tableRef}>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('filename_text')}</TableCell>
                            <TableCell>{t('actions_text')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ? <TableRow >
                            <TableCell colSpan={2}>
                                <Typography style={{ justifyContent: 'center' }} className="loading-text">{t('getting_files_text')}<span className="loading-ellipsis" /></Typography>
                            </TableCell>
                        </TableRow> : allFiles.length ? allFiles.map(file => {
                            return <TableRow key={file.key}>
                                <TableCell >
                                    {file.name}
                                </TableCell>
                                <TableCell><Tooltip title={t('download_text')}><Button className={classes.downloadButton}><FaDownload onClick={async () => {
                                    const url = await getSessionFile(file.key);
                                    await downloadFile(url, file);
                                }} /></Button></Tooltip></TableCell>
                            </TableRow>
                        }) : <TableRow><TableCell >
                        N / A 
                    </TableCell></TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </Root>)
    );

})