import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Paper, CircularProgress, Stepper, Step, StepLabel, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import InfoMessage from './InfoMessage';
// const models = require('@vladmandic/face-api/model');
import avHelp from './resources/av-help.png'
import camHelp from './resources/cam-help.png'
import LoadingStepIcon from './LoadingStepIcon';
import MLWebcam from './MLWebcam';
import { useTranslation } from 'react-i18next';

const PREFIX = 'PhotoUpload';

const short = require('short-uuid');

const classes = {
    root: `${PREFIX}-root`,
    webcam: `${PREFIX}-webcam`,
    spinnerBox: `${PREFIX}-spinnerBox`,
    spinner: `${PREFIX}-spinner`,
    label: `${PREFIX}-label`,
    infoBox: `${PREFIX}-infoBox`,
    panel: `${PREFIX}-panel`,
    buttonBox: `${PREFIX}-buttonBox`,
    button: `${PREFIX}-button`,
    loadingMessage: `${PREFIX}-loadingMessage`,
    loadingEllipsis: `${PREFIX}-loadingEllipsis`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    // width: '100%',
    // height: '100%'
    '@keyframes ellipsis': {
        'to': {
            width: '1.25em'
        }
    },
    [`& .${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        // width: '85%',
        // height: '85%'
    },
    [`& .${classes.webcam}`]: {
        // display: hideVideo ? 'none' : 'block',
        height: '100%',
        width: '100%'
    },
    [`& .${classes.spinnerBox}`]: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    [`& .${classes.spinner}`]: {
        margin: '1rem'
    },
    [`& .${classes.label}`]: {
        fontSize: '1rem',
    },
    [`& .${classes.infoBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(6.5rem + 6px)',
        // width: '85%'
    },
    [`& .${classes.buttonBox}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 6px 6px 6px'
    },
    [`& .${classes.button}`]: {
        margin: '0px 12px'
    },
    [`& .${classes.loadingMessage}`]: {
        display: 'inline-flex'
    },
    [`& .${classes.loadingEllipsis}`]: {
        minWidth: '1.25em',
        '&::after': {
            overflow: 'hidden',
            display: 'inline-block',
            verticalAlign: 'bottom',
            animation: '$ellipsis steps(4, end) 900ms infinite',
            content: '"\\2026"', /* ascii code for the ellipsis character */
            width: '0px'
        }
    }
})

const imgFormat = {
    type: "image/png",
    ext: ".png"
}

const PhotoUpload = React.memo(({ uploadPhoto, onComplete, onError, noRetry }) => {
    const [status, setStatus] = useState({})
    const [faceDeteced, setFaceDetected] = useState(false)
    const [imgData, setImgData] = useState()
    const [uploading, setUploading] = useState(false)

    const detectionRef = useRef()
    const resetRef = useRef()
    const { t } = useTranslation('self_tech_check_step_5');

    const helpComponents = {
        "Permission denied": {
            message: t('enable_camera_permissions'),
            slides: [
                {
                    text: t('locate_button_in_URL_and_click'),
                    img: avHelp
                },
                {
                    text: t('select_always_allow'),
                    img: camHelp
                },
                {
                    text: t('close_guide_and_retry'),
                    // img: camHelp
                }
            ]
        },
        "Failed to Detect": {
            message: t('failed_to_detect')
        }
    }

    const onDetectionChange = useCallback((event) => {
        // console.log('DETECTION CHANGE', event.detail.detected)
        setFaceDetected(event.detail.detected)
    }, [])

    useEffect(() => {
        return () => {
            if (detectionRef.current) detectionRef.current.removeEventListener('detection_change', onDetectionChange)
        }
    }, [onDetectionChange])

    const handleLoaded = useCallback(() => {
        detectionRef.current.addEventListener('detection_change', onDetectionChange)
    }, [onDetectionChange])

    const handleError = useCallback((error, resetCallback) => {
        setStatus({
            failed: true,
            error
        })
        resetRef.current = resetCallback
        if (onError) onError(error)
    }, [onError])

    const handleRetry = useCallback(() => {
        setStatus({})
        if (resetRef.current) resetRef.current()
    }, [])

    const handlePhotoUpload = useCallback((image) => {
        // console.log('GOT PHOTO', image)
        setImgData(image)
    }, [])

    const handleRetake = useCallback(() => {
        setImgData()
    }, [])

    const handleNext = useCallback(async () => {
        setUploading(true)
        try {
            const res = await fetch(imgData);
            const blob = await res.blob();
            const imgFile = new File([blob], `tc_photo${imgFormat.ext}`, { type: imgFormat.type });
            await uploadPhoto(imgFile)
            // setUploading(false)
            onComplete()
        } catch (err) {
            setUploading(false)
        }
    }, [imgData, uploadPhoto, onComplete])

    return (
        <Root>
            {uploading ? <>
                <div className={classes.root}>
                    <CircularProgress className={classes.spinner} />
                    <Typography className={classes.loadingMessage}>
                        {t('uploading_photo')}<span className={classes.loadingEllipsis} />
                    </Typography>
                </div>
            </> : <>
                <div className={classes.root}>
                    <MLWebcam
                        withAudio={false}
                        withDetection={true}
                        withPhoto={true}
                        imgFormat={imgFormat.type}
                        disablePhotoCapture={!faceDeteced || !!imgData}
                        detectionInterval={1500}
                        detections={detectionRef}
                        onUserMedia={handleLoaded}
                        onUserMediaError={handleError}
                        onPhotoCapture={handlePhotoUpload}
                        hidden={!!imgData}
                    />
                    {imgData && <img src={imgData} />}
                    {status.failed && <div className={classes.infoBox}>
                        <InfoMessage
                            {...status}
                            messages={helpComponents}
                            defaultMessage={t('issue_with_cam_and_mic')}
                            noRetry={noRetry}
                        />
                    </div>}
                </div>
                {(imgData || status.failed) && <div className={classes.buttonBox}>
                    {status.failed ? <>
                        <Button className={classes.button} color='primary' variant='contained' onClick={handleRetry}>
                            {t('retry')}
                        </Button>
                    </> : <>
                        <Button className={classes.button} color='primary' variant='contained' disabled={!imgData} onClick={handleRetake}>
                            {t('retake_photo')}
                        </Button>
                        <Button className={classes.button} color='primary' variant='contained' disabled={!imgData} onClick={handleNext}>
                            {t('next')}
                        </Button>
                    </>}
                </div>}
            </>}
        </Root>
    )
})

export default PhotoUpload;