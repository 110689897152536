import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/material/styles';
import HelpModal from './HelpModal';
import InfoMessage from './InfoMessage';
import YesNoQuestion from './YesNoQuestion';
import { useTranslation } from 'react-i18next';

const PREFIX = 'SessionFlowChecker';

const classes = {
    root: `${PREFIX}-root`,
    stepper: `${PREFIX}-stepper`,
    label: `${PREFIX}-label`,
    spinner: `${PREFIX}-spinner`,
    error: `${PREFIX}-error`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    // width: '85%',
    // height: '85%',
    [`& .${classes.stepper}`]: {
        height: '75%',
        justifyContent: 'space-evenly'
    },
    [`& .${classes.label}`]: {
        fontSize: '1rem',
    },
    [`& .${classes.spinner}`]: {
        position: 'absolute',
        margin: '1rem'
    },
    [`& .${classes.error}`]: {
        minHeight: 'calc(3rem + 6px)'
    }
});

const VideoQuestion = (props) => {
    const { t } = useTranslation('self_tech_check_step_4');
    return <YesNoQuestion
        {...props}
        question={t('could_you_see')}
    />
}

const AudioQuestion = (props) => {
    const { t } = useTranslation('self_tech_check_step_4');
    return <YesNoQuestion
        {...props}
        question={t('could_you_hear')}
    />
}

const checkQuestions = [
    {
        key: 'video',
        component: VideoQuestion
    },
    {
        key: 'audio',
        component: AudioQuestion
    }
]

const SessionFlowChecker = React.memo(({ onComplete, onError, noRetry, externalStatus }) => {

    const [status, setStatus] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const { t } = useTranslation('self_tech_check_step_4');

    const statusRef = useRef({})

    const helpComponents = {
        "Viewing Error": {
            message: ({ cause }) => {
                if (cause.question === 'video') return t('viewing_error_see')
                else return t('viewing_error_hear')
            }
        },
        "Playback Error": {
            message: t('playback_error')
        }
    }

    const QuestionComponent = useMemo(() => (checkQuestions[status.questionIndex]?.component), [status])

    useEffect(() => {
        statusRef.current = status
    }, [status])

    useEffect(() => {
        if (externalStatus.error) {
            setStatus({
                failed: true,
                error: externalStatus.error
            })
            onError(externalStatus.error, true)
        } else if (externalStatus.complete) {
            setStatus({
                videoComplete: true,
                questionIndex: 0
            })
        }
    }, [externalStatus, onError])

    const handleQuestionAnswer = useCallback((yes) => {
        if (yes) {
            const callback = statusRef.current.questionIndex < checkQuestions.length - 1 ? () => {
                setStatus(prev => ({
                    videoComplete: true,
                    questionIndex: prev.questionIndex + 1
                }))
            } : undefined
            onComplete(callback)
        } else {
            const answerError = new Error('Viewing Error', { cause: { question: checkQuestions[statusRef.current.questionIndex].key } })
            onComplete(() => {
                setSubmitted(true)
                setStatus({
                    failed: true,
                    error: answerError
                })
                onError(answerError, () => {
                    setSubmitted(false)
                })
            })
        }
    }, [onComplete, onError])

    return (
        <Root>
            {status.videoComplete && <QuestionComponent
                onAnswer={handleQuestionAnswer}
                submitted={submitted}
            />}
            <InfoMessage
                {...status}
                messages={helpComponents}
                defaultMessage={t('error_click_to_retry')}
                noRetry={!externalStatus.error && noRetry}
            />
        </Root>
    );
})

export default SessionFlowChecker;