import React, { useRef, useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import * as workerTimers from 'worker-timers'
import config from './config.json';
import { useTranslation } from 'react-i18next';

const PREFIX = 'hooks';

const classes = {
    panel: `${PREFIX}-panel`,
    buttonBox: `${PREFIX}-buttonBox`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    // width: '100%',
    // height: '100%'
    [`& .${classes.buttonBox}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto 6px 6px 6px'
    }
});

export const useEventListener = (eventName, handler, passive = true, element = window) => {

    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        const eventListener = (event) => {
            if (!passive) {
                event.preventDefault();
            }
            savedHandler.current(event)
        };

        element.addEventListener(eventName, eventListener, { passive });

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element, passive]);
};

let storageKeys = []

export const clearLocalStorage = (keyList) => {
    (keyList || storageKeys).forEach((key) => {
        window.localStorage.removeItem(key)
    })
}

export const useLocalStorage = (key, initialValue) => {
    storageKeys = [...new Set([...storageKeys, key])]

    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            console.log(key, item)
            return (item && item !== 'undefined') ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(storedValue));
        } catch (error) {
            console.log(error);
        }
    }, [key, storedValue])

    return [storedValue, setStoredValue];
}

export const useIsOnline = (token, interval = 3000) => {

    const [isOnline, setIsOnline] = useState(true)
    const pollRef = useRef()

    useEffect(() => {
        pollRef.current = workerTimers.setInterval(async () => {
            try {
                const check = await fetch(`https://${config.rest.sessionGuestAPI}/ping`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                if (check.status === 200) {
                    setIsOnline(true)
                } else {
                    setIsOnline(false)
                }
            } catch (e) {
                setIsOnline(false)
            }

        }, interval);
        return () => {
            workerTimers.clearInterval(pollRef.current)
        }
    }, [interval, token])

    return { isOnline };
}

export const useSystemCheck = () => {

    const systemInfo = useRef({})

    useEffect(() => {
        if (navigator.deviceMemory) {
            systemInfo.current.deviceMemory = navigator.deviceMemory
        }
        if (navigator.hardwareConcurrency) {
            systemInfo.current.hardwareConcurrency = navigator.hardwareConcurrency
        }
        if (performance.memory) {
            systemInfo.current = {
                ...performance.memory,
                ...systemInfo.current
            }
        }
    }, [])

    return {
        systemInfo: systemInfo.current
    };
}

export const useRetry = (retryLimit) => {

    const [retryCount, setRetryCount] = useState(0)
    const [retryLimitReached, setRetryLimitReached] = useState(false)

    useEffect(() => {
        if (retryCount) {
            if (retryCount >= retryLimit) {
                setRetryLimitReached(true)
            }
        }
    }, [retryCount, retryLimit])

    const incrementRetry = useCallback(() => {
        setRetryCount(prev => prev + 1)
    }, [])

    return {
        incrementRetry,
        retryLimitReached
    };
}

export const withNextRetry = (WrappedComponent, { retryLimit, noFail, failNext, forDisplay, goToSessionFlow, cancelRegistration }) => React.memo(({ onComplete, onError, onRetry, afterFail, ...props }) => {

    const [retry, setRetry] = useState(false)
    const [failed, setFailed] = useState(false)
    const [disabled, setDisabled] = useState(!forDisplay)
    const { t } = useTranslation('buttons');

    const retryCount = useRef(0)
    const nextFn = useRef()

    useEffect(() => {
        // if (retry) setRetryCount(prev => prev + 1)
        if (retry) retryCount.current++
    }, [retry])

    const showNext = useCallback((handler) => {
        nextFn.current = handler instanceof Function ? handler : undefined
        setDisabled(false)
    }, [])

    const showRetry = useCallback((error, handler, force) => {
        nextFn.current = handler instanceof Function ? handler : undefined
        const forceFail = force || (typeof handler === 'boolean' && handler)
        const noRetry = forceFail || retryCount.current >= retryLimit
        const hasFailed = !noFail && noRetry
        if (!hasFailed) {
            setDisabled(!!(noFail && !failNext))
            setRetry(!noFail)
        }
        setFailed(noRetry && !(noFail && failNext))
        onError(error, hasFailed, cancelRegistration)
    }, [onError])

    const handleNextRetry = useCallback(() => {
        if (nextFn.current) {
            setDisabled(true)
            const callback = nextFn.current
            nextFn.current = null
            callback()
        } else if (!retry) {
            onComplete(goToSessionFlow)
        }
        if (retry) {
            setDisabled(true)
            setRetry(false)
            onRetry(goToSessionFlow)
        }
    }, [retry, onComplete, onRetry])

    const handleFail = useCallback(() => {
        afterFail()
    }, [afterFail])

    return (
        <Root>
            <WrappedComponent
                {...props}
                onComplete={showNext}
                onError={showRetry}
            />
            <div className={classes.buttonBox}>
                {failed ?
                    <Button color='primary' variant='contained' onClick={handleFail}>
                        {t('schedule_guided_tech_check')}
                    </Button>
                    :
                    <Button color='primary' variant='contained' disabled={disabled} onClick={handleNextRetry}>
                        {retry ? t('retry_button') : t('NEXT_button')}
                    </Button>
                }
            </div>
        </Root>
    );
});
