import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import YesNoQuestion from './YesNoQuestion';
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next';

const PREFIX = 'EventConfirmChecker';

const classes = {
    root: `${PREFIX}-root`,
    stepper: `${PREFIX}-stepper`,
    label: `${PREFIX}-label`,
    spinner: `${PREFIX}-spinner`,
    error: `${PREFIX}-error`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    // width: '85%',
    // height: '85%',
    [`& .${classes.stepper}`]: {
        height: '75%',
        justifyContent: 'space-evenly'
    },
    [`& .${classes.label}`]: {
        fontSize: '1rem',
    },
    [`& .${classes.spinner}`]: {
        position: 'absolute',
        margin: '1rem'
    },
    [`& .${classes.error}`]: {
        minHeight: 'calc(3rem + 6px)'
    }
});

// const helpComponents = {
//     "Can Not Attend": {
//         message: "Please switch to the device you plan to use on the day of the event and then re-open the link you were emailed to restart your tech check."
//     }
// } // not in use

const timezone = moment.tz(moment.tz.guess()).zoneAbbr()

const EventConfirmChecker = React.memo(({ checkInTime, onComplete, onError, noRetry }) => {

    const [status, setStatus] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const { t } = useTranslation('self_tech_check_step_6');

    const checkInDateStr = new Date(checkInTime).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    const checkInTimeStr = new Date(checkInTime).toLocaleString('en-us', { timeStyle: 'short' })

    const handleQuestionAnswer = useCallback((yes) => {
        if (yes) {
            // const callback = status.questionIndex < checkQuestions.length - 1 ? () => {
            //     setStatus(prev => ({
            //         videoComplete: true,
            //         questionIndex: prev.questionIndex + 1
            //     }))
            // } : undefined
            onComplete()
        } else {
            const answerError = new Error('Can Not Attend')
            onComplete(() => {
                setStatus({
                    failed: true,
                    error: answerError
                })
                onError(answerError, () => {
                    setSubmitted(true)
                })
            })
        }
    }, [onComplete, onError])

    return (
        <Root>
            <YesNoQuestion
                onAnswer={handleQuestionAnswer}
                question={t('event_info', { date: checkInDateStr, time: checkInTimeStr, timezone: timezone })}
                yesLabel={t('SIGN_ME_UP_button')}
                noLabel={t('CHANGED_MY_MIND_button')}
                submitted={submitted}
            />
            {/* <InfoMessage
                {...status}
                messages={helpComponents}
                defaultMessage="There was an error. Please click below to retry."
                // noRetry={noRetry}
            /> */}
        </Root>
    );
})

export default EventConfirmChecker;