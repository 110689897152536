import React, { useCallback, useState} from 'react'
import { styled } from '@mui/material/styles';
import { Fab, Tooltip, Paper, Zoom, CircularProgress, Popover } from '@mui/material';
import FileCopy from '@mui/icons-material/FileCopy';
import Reply from '@mui/icons-material/Reply';
import VideocamOutlined from '@mui/icons-material/VideocamOutlined';
import { getZoomMeetingDetailsPromise } from './services/clientService';
import config from './config.json';
import { SessionFiles } from './SessionFiles';
import { useTranslation } from 'react-i18next';


const PREFIX = 'ClientOptionsPopup';

const classes = {
    filePanel: `${PREFIX}-filePanel`,
    optionsPopup: `${PREFIX}-optionsPopup`,
    optionsFabBox: `${PREFIX}-optionsFabBox`,
    optionFab: `${PREFIX}-optionFab`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    [`& .${classes.filePanel}`]: {
        // position: 'absolute',
        // top: 0,
        // left: 0,
        width: 'calc(200px + (650 - 200) * ((100vw - 100px) / (2400 - 100)))',
        position: 'relative',
        zIndex: 5,
        overflowY: 'auto',
        maxHeight: 300
    },
    [`& .${classes.optionsPopup}`]: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        // zIndex: '10',
        // padding: '1rem',
        // boxSizing: 'content-box'
        height: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'flex-end'
    },
    [`& .${classes.optionsFabBox}`]: {
        margin: '1rem',
        position: 'relative'
    },
    [`& .${classes.optionFab}`]: {
        position: 'relative',
        zIndex: 5,
        opacity: 0.5,
        '&:hover': {
            opacity: 1
        }
    }
});

const ClientOptionsPopup = React.memo(({ isChatPopup, zoomMeetingId, zoomRegistrantId, encryptedProjectId, sessionId, token, setIsSessionFilesOpen }) => {


    const {t} = useTranslation('client_options');


    const iconOffset = isChatPopup ? 0 : 70; // offset placement of icons by 70 if chat popup is present

    const [showSessionFiles, setShowSessionFiles] = useState(false);
    const [loadingZoom, setLoadingZoom] = useState(false);
    const [filesPopoverAnchor, setFilesPopoverAnchor] = useState(null);
    const [sessionFileCount, setSessionFileCount] = useState(0);

    const openShowSessionFiles = useCallback((event) => {
        setShowSessionFiles(prev => {
            if (!prev) {
                setFilesPopoverAnchor(event.currentTarget)
                setIsSessionFilesOpen(true);
            }
            return !prev
        });
    }, [setIsSessionFilesOpen]);

    const closeShowSessionFiles = useCallback(() => {
        setShowSessionFiles(false)
        setFilesPopoverAnchor(null);
        setIsSessionFilesOpen(false);
    }, [setIsSessionFilesOpen]);

    const getZoomDetails = useCallback(async (meetingId, registrantId) => {
        const data = await getZoomMeetingDetailsPromise(meetingId, registrantId, token);
        return data;
    }, [token]);

    const joinZoomMeeting = useCallback(async () => {
        setLoadingZoom(true);
        const data = await getZoomDetails(zoomMeetingId, zoomRegistrantId);
        window.open(data?.registration?.join_url || data?.details?.join_url, '_blank');
        setLoadingZoom(false);
    }, [getZoomDetails, zoomMeetingId, zoomRegistrantId]);

    const redirectToCPP = useCallback(() => {
        const url = `https://${config.dialUrl}/client/project/${encryptedProjectId}?id=${token}`;
        window.open(url, '_self');
    }, [encryptedProjectId, token]);

    return (
        (<Root>
            <div style={{ right: 70 - iconOffset, bottom: 0 }} className={classes.optionsPopup}>
                <Tooltip title={t('shared_files_tooltip')}>
                    <div className={classes.optionsFabBox} onClick={openShowSessionFiles}>
                        <Zoom
                            key={'session-files-fab'}
                            in={true}
                            unmountOnExit
                        >
                            <Fab color="primary" className={classes.optionFab} >
                                <FileCopy />
                            </Fab>
                        </Zoom>
                    </div>
                </Tooltip>
                <Popover
                    open={showSessionFiles}
                    anchorEl={filesPopoverAnchor}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={closeShowSessionFiles}
                    style={{marginTop: -5 * sessionFileCount}}
                >
                    <Paper
                        className={classes.filePanel}
                        elevation={3}
                    >
                        <SessionFiles setSessionFileCount={setSessionFileCount} sessionId={sessionId} token={token} />
                    </Paper>
                </Popover>
            </div>
            {zoomMeetingId && <div style={{ right: 140 - iconOffset, bottom: 0 }} className={classes.optionsPopup}>
                <Tooltip title={t('zoom_meeting_tooltip')}>
                    <div className={classes.optionsFabBox} onClick={joinZoomMeeting}>
                        <Fab color="primary" className={classes.optionFab} >
                            {loadingZoom && <CircularProgress size={'2.5rem'} style={{ position: 'absolute', zIndex: '1', color: 'white' }} />}
                            <VideocamOutlined />
                        </Fab>
                    </div>
                </Tooltip>
            </div>}
            {encryptedProjectId && <div style={zoomMeetingId ? { right: 210 - iconOffset, bottom: 0 } : { right: 140 - iconOffset, bottom: 0 }} className={classes.optionsPopup}>
                <Tooltip title={t('back_to_cpp_tooltip')}>
                    <div className={classes.optionsFabBox} onClick={redirectToCPP}>
                        <Fab color="primary" className={classes.optionFab} >
                            <Reply />
                        </Fab>
                    </div>
                </Tooltip>
            </div>}
        </Root>)
    );

})

export default (ClientOptionsPopup)