
import React from 'react';
import { ASIDial, BasicDial } from './DialSkins';
import { ShapeLight, MeterLight } from './Lights';
import './Dial.css'

const pickHex = (color1, color2, weight) => {
    const w1 = weight;
    const w2 = 1 - w1;
    const rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)];
    return rgb;
}

const pickHex3 = (color1, color2, color3, weight) => {
    const w1 = weight <= 0.5 ? 2 * weight : 2 * (weight - 0.5);
    const w2 = 1 - w1;
    const rgb = weight <= 0.5 ? [
        Math.round(color2[0] * w1 + color1[0] * w2),
        Math.round(color2[1] * w1 + color1[1] * w2),
        Math.round(color2[2] * w1 + color1[2] * w2)
    ] : [
            Math.round(color3[0] * w1 + color2[0] * w2),
            Math.round(color3[1] * w1 + color2[1] * w2),
            Math.round(color3[2] * w1 + color2[2] * w2)
        ];
    return rgb;
}

const Dial = React.memo(({ dialRef, dialStyle, dialColor, currentTheme, currentAngle, maxAngle, numberOfMeters, useShapes, isOn }) => {
    const meters = [];
    const angle = maxAngle / (numberOfMeters - 1);
    const base = currentTheme === 'light' ? 180 : 16;
    const outward = true;

    let rot = 0;

    for (let i = 0; i < numberOfMeters; i++) {
        const rgb = pickHex3([225, 85, 84], [255, 255, 255], [59, 178, 115], rot / maxAngle);
        // const rgb = pickHex([59, 178, 115], [225, 85, 84], rot / maxAngle);

        // const rgb = pickHex3([202,43,0], [255,255,255], [145,189,9], rot / maxAngle);
        // const rgb = pickHex([202,43,0], [145,189,9], rot / maxAngle);

        // const rgb = pickHex3([165, 76, 157], [93, 131, 192], [20, 185, 226], rot / maxAngle);
        // const rgb = pickHex([20, 185, 226], [165, 76, 157], rot / maxAngle);
        const glow = pickHex([255, 255, 255], rgb, .2);
        const inset = pickHex([base, base, base], rgb, .7);
        if (useShapes && [0, Math.round((numberOfMeters - 1) / 3), Math.round(2 * (numberOfMeters - 1) / 3), numberOfMeters - 1].includes(i)) {
            if ((i === numberOfMeters - 1)) rot = maxAngle; //prevent problems with exponential decimals
            let isVisible = outward ? (rot >= maxAngle / 2 && currentAngle >= rot) || (rot <= maxAngle / 2 && currentAngle <= rot) : currentAngle >= rot
            meters.push(<ShapeLight key={`meter${i}`}
                type={i < numberOfMeters / 2 ? '-' : '+'}
                double={[0, numberOfMeters - 1].includes(i)}
                backgroundColor={`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`}
                glowColor={`rgba(${glow[0]},${glow[1]},${glow[2]},0.55)`}
                insetColor={`rgb(${inset[0]},${inset[1]},${inset[2]})`}
                highlight={currentTheme === 'light' ? 'white' : 'black'}
                clearColor={'rgba(255,255,255,0.45)'}
                rotation={rot}
                visible={isVisible}
                show={isOn}
                maxAngle={maxAngle}
            />)
        } else {

            let isVisible = outward ? (rot >= maxAngle / 2 && currentAngle >= rot) || (rot <= maxAngle / 2 && currentAngle <= rot) : currentAngle >= rot
            meters.push(<MeterLight key={`meter${i}`}
                backgroundColor={`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`}
                glowColor={`rgba(${glow[0]},${glow[1]},${glow[2]},0.55)`}
                insetColor={`rgb(${inset[0]},${inset[1]},${inset[2]})`}
                rotation={rot}
                visible={isVisible && isOn}
                notch={useShapes}
            />)
        }
        rot += angle;
    }

    return (
        <div className="dial-container">
            <div className="dial-meter" style={{
                transform: `rotate(${(180 - maxAngle) / 2}deg)`
            }}>
                {meters}
            </div>
            <div className="dial-box" ref={dialRef} style={{
                transform: `translateZ(1px) rotate(${currentAngle - maxAngle / 2}deg)`
            }}>
                {dialStyle === 'Chicken Head (ASI)' ? <ASIDial color={dialColor} /> : <BasicDial color={dialColor} />}
            </div>
        </div>
    )

})

export default Dial