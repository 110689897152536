import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import moment from 'moment'

const PREFIX = 'SessionInfoBanner';

const classes = {
    titleBox: `${PREFIX}-titleBox`
};

const Root = styled('div')(({hidden}) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem',
        visibility: hidden ? 'hidden' : 'visible'
}));

const SessionInfoBanner = React.memo(({
    videoTitle,
    sessionDate,
    hidden,
    ...props
}) => {


    return (
        <Root hidden={hidden}>
            <Typography variant="h3">{videoTitle}</Typography>
            <Typography variant="h5">{moment(sessionDate).format('MMMM Do YYYY, h:mm a')}</Typography>
        </Root>
    );

})

export default SessionInfoBanner;