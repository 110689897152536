import config from '../config.json'

export const getZoomMeetingDetailsPromise = (meetingId, registrantId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/zoom/details?meetingId=${meetingId}&registrantId=${registrantId}`, {
        method: 'GET',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

export const getAllSessionFilesPromise = (sessionId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/session/files?sessionId=${sessionId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json().catch(err => {
                return []; // return nothing
            });
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })

export const getSessionFilePromise = (sessionId, filename, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/session/file?sessionId=${sessionId}&filename=${filename}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    })
