import React, { useState, useRef, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import * as workerTimers from 'worker-timers'
import LoadingStepIcon from './LoadingStepIcon';
import UAParser from 'ua-parser-js'
import HelpModal from './HelpModal';
import { browserCheck, isMobileDevice, supportedEncryptedMediaExtensions } from './utils'
import InfoMessage from './InfoMessage';
import { useTranslation } from 'react-i18next';

const PREFIX = 'BrowserConfigChecker';

const classes = {
    root: `${PREFIX}-root`,
    stepper: `${PREFIX}-stepper`,
    label: `${PREFIX}-label`,
    spinner: `${PREFIX}-spinner`,
    error: `${PREFIX}-error`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    // width: '85%',
    // height: '85%',
    [`& .${classes.stepper}`]: {
        // height: '75%',
        flex: 1,
        justifyContent: 'space-evenly'
    },
    [`& .${classes.label}`]: {
        fontSize: '1rem',
    },
    [`& .${classes.spinner}`]: {
        position: 'absolute',
        margin: '1rem'
    },
    [`& .${classes.error}`]: {
        minHeight: 'calc(3rem + 6px)'
    }
});

const parser = new UAParser();

// const drmLinks = {
//     "Chrome": [
//         'Go to chrome://settings/content',
//         'Scroll down to the bottom of the page and click on Protected Content IDs',
//         'Click the toggle button so it says "Allow sites to play protected content (recommended)"'
//     ],
//     "Edge": [
//         'Go to edge://settings/content',
//         'Scroll down to the bottom of the page and click on Protected Content IDs',
//         'Click the toggle button so it says "Allow sites to play protected content (recommended)"'
//     ],
//     "Firefox": [
//         'Go to about: preferences',
//         'Scroll down to the "Digital Right Management (DRM) Content" section',
//         'Tick the "Play DRM-controlled content" box',
//         "https://support.mozilla.org/en-US/kb/enable-drm",
//     ],
//     "Safari": "" //NEED INSTRUCTIonS
// }



const BlankSpacer = () => <div />

const BrowserConfigChecker = React.memo(({ allowedBrowsers = [], allowMobile = false, onComplete, onError, noRetry }) => {

    const [currentStepIndex, setCurrentStepIndex] = useState(0)
    const [status, setStatus] = useState({})
    // const [error, setError] = useState()
    const { t } = useTranslation('self_tech_check_step_2');
    // const { incrementRetry, retryLimitReached } = useRetry(3)
    // const { systemInfo } = useSystemCheck()

    const timerRef = useRef()
    const paramsRef = useRef({ allowedBrowsers, allowMobile })
    const errorRef = useRef(onError)

    useEffect(() => {
        return () => {
            if (timerRef.current) workerTimers.clearTimeout(timerRef.current)
        }
    }, [])

    useEffect(() => {
        paramsRef.current = { allowedBrowsers, allowMobile }
    }, [allowedBrowsers, allowMobile])

    useEffect(() => {
        errorRef.current = onError
    }, [onError])

    useEffect(() => {
        if (currentStepIndex < checkSteps.length) {
            timerRef.current = workerTimers.setTimeout(() => {
                timerRef.current = null
                checkSteps[currentStepIndex].checkFunction(paramsRef.current).then(() => {
                    setCurrentStepIndex(prev => prev + 1)
                }).catch((error) => {
                    console.log(error)
                    setStatus({
                        failed: true,
                        failedStep: currentStepIndex,
                        error
                    })
                    if (errorRef.current) errorRef.current(error, () => {
                        setStatus({})
                        setCurrentStepIndex(0)
                    }, !checkSteps[currentStepIndex].allowRetry)
                })
            }, 750)
        } else {
            onComplete()
        }
    }, [currentStepIndex, onComplete])

    const helpComponents = {
        "Mobile Device": {
            message: t('mobile_device_not_supported')
        },
        "Incompatible Browser": {
            message: ({ cause }) => cause.allowedBrowsers.length > 1 ? t('incompatible_browser_check_one_of_the_following', { browser: cause.browser, allowedBrowsers: cause.allowedBrowsers.join(',') }) : t('incompatible_browser_check_following', { browser: cause.browser, allowedBrowsers: cause.allowedBrowsers.join(',') })
        },
        "No Web Worker Support": {
            message: t('no_web_worker_support')
        },
        "No DRM Support": {
            message: t('no_DRM_support'),
            // slides: [ //ADD BROWSER-SPECIFIC DRM LINKS
            //     {
            //         text: "Step 1: Do This all the skills you need to complete this task have been inside you this entire time",
            //         // img: videoHelp1
            //     },
            // ]
        }
    };

    const checkSteps = useMemo(() => [
        // {
        //     label: 'Checking System Info',
        //     checkFunction: async () => {
        //         let systemInfo = {}
        //         if (navigator.deviceMemory) {
        //             systemInfo.deviceMemory = navigator.deviceMemory
        //         }
        //         if (navigator.hardwareConcurrency) {
        //             systemInfo.hardwareConcurrency = navigator.hardwareConcurrency
        //         }
        //         if (performance.memory) {
        //             systemInfo = {
        //                 ...performance.memory,
        //                 ...systemInfo
        //             }
        //             // const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = performance.memory
        //         }
        //     }
        // },
        {
            label: t('checking_device_type'),
            checkFunction: async ({ allowMobile }) => {
                if (!allowMobile && isMobileDevice()) {
                    throw new Error(`Mobile Device`)
                }
            },
            allowRetry: true
        },
        {
            label: t('checking_browser_type'),
            checkFunction: async ({ allowedBrowsers }) => {
                parser.setUA(navigator.userAgent);
                const broswerInfo = parser.getResult()
                const browser = await browserCheck(broswerInfo.browser.name)
                if (!allowedBrowsers.includes(browser)) {
                    throw new Error(`Incompatible Browser`, { cause: { browser, allowedBrowsers } })
                }
            },
            allowRetry: true
        },
        {
            label: t('checking_browser_compatibility'),
            checkFunction: async () => {
                if (typeof (Worker) === "undefined") {
                    throw new Error(`No Web Worker Support`)
                }
                // EME CHECK
                const availableEMEs = await supportedEncryptedMediaExtensions()
                if (!availableEMEs.length) {
                    // parser.setUA(navigator.userAgent);
                    // const broswerInfo = parser.getResult()
                    // const browser = await browserCheck(broswerInfo.browser.name)
                    // throw new Error("No DRM Support", { cause: { browser } })
                    throw new Error("No DRM Support")
                }
            },
        },
        // {
        //     label: 'Checking DRM Compatibility',
        //     checkFunction: async () => {
        //         // EME CHECK
        //         const availableEMEs = await supportedEncryptedMediaExtensions()
        //         if (!availableEMEs.length) {
        //             throw new Error("No DRM Support")
        //         }
        //     },
        //     allowRetry: true
        // }
        // BROWSER MINIMUM VERSION
    ], [t]);

    return (
        <Root>
            <Stepper className={classes.stepper} activeStep={currentStepIndex} orientation="vertical" connector={<BlankSpacer />}>
                {checkSteps.map((step, i) => (<Step key={`browser-check-step${i}`}>
                    <StepLabel
                        classes={{ label: classes.label }}
                        StepIconComponent={LoadingStepIcon}
                        error={status.failed && status.failedStep === i}
                        optional={status.failed && status.failedStep === i && <Typography variant="overline" color="error">
                            {status.error.message}
                        </Typography>}
                    >
                        {step.label}
                    </StepLabel>
                </Step>))}
            </Stepper>
            <InfoMessage
                {...status}
                messages={helpComponents}
                defaultMessage={t('current_browser_incompatible')}
                noRetry={noRetry && checkSteps[currentStepIndex].allowRetry}
            />
        </Root>
    )
})

export default BrowserConfigChecker;