import React from 'react';
// import { useState, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
// import './App.css';

export const Plus = React.memo((props) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="svg" style={props.style} width="100%" height="100%" viewBox="0 0 344 344" stroke="transparent" strokeWidth="1">
            <filter id="inset-shadow">
                <feOffset dx='0' dy='-1' />
                <feGaussianBlur
                    stdDeviation='9'
                    result='offset-blur'
                />
                <feComposite
                    operator='out'
                    in='SourceGraphic'
                    in2='offset-blur'
                    result='inverse'
                />
                <feFlood
                    floodColor={props.insetColor}
                    floodOpacity='0.75'
                    result='color'
                />
                <feComposite
                    operator='in'
                    in='color'
                    in2='inverse'
                    result='shadow'
                />
                <feComposite
                    operator='over'
                    in='shadow'
                    in2='SourceGraphic'
                />
            </filter>
            <filter id="inset-shadow2">
                <feOffset dx='0' dy='0' />
                <feGaussianBlur
                    stdDeviation='24'
                    result='offset-blur'
                />
                <feComposite
                    operator='out'
                    in='SourceGraphic'
                    in2='offset-blur'
                    result='inverse'
                />
                <feFlood
                    floodColor={props.highlight}
                    floodOpacity={props.highlight === 'white' ? '0.2' : '0.8'}
                    result='color'
                />
                <feComposite
                    operator='in'
                    in='color'
                    in2='inverse'
                    result='shadow'
                />
                <feComposite
                    operator='over'
                    in='shadow'
                    in2='SourceGraphic'
                />
            </filter>
            {/* <clipPath id="plus-path" clipPathUnits="objectBoundingBox" transform="scale(0.002177 0.002177)">
                <path d="M459.319,229.668c0,22.201-17.992,40.193-40.205,40.193H269.85v149.271c0,22.207-17.998,40.199-40.196,40.193   c-11.101,0-21.149-4.492-28.416-11.763c-7.276-7.281-11.774-17.324-11.769-28.419l-0.006-149.288H40.181   c-11.094,0-21.134-4.492-28.416-11.774c-7.264-7.264-11.759-17.312-11.759-28.413C0,207.471,17.992,189.475,40.202,189.475h149.267   V40.202C189.469,17.998,207.471,0,229.671,0c22.192,0.006,40.178,17.986,40.19,40.187v149.288h149.282   C441.339,189.487,459.308,207.471,459.319,229.668z" />
            </clipPath> */}
            <g filter="url(#inset-shadow2)" style={{isolation: 'isolate'}}>
                <path filter="url(#inset-shadow)" d="M 344.238281 172.125 C 344.238281 188.765625 330.753906 202.25 314.105469 202.25 L 202.242188 202.25 L 202.242188 314.121094 C 202.242188 330.765625 188.75 344.25 172.117188 344.242188 C 163.796875 344.242188 156.265625 340.878906 150.820312 335.425781 C 145.367188 329.972656 141.996094 322.445312 142 314.128906 L 141.992188 202.246094 L 30.113281 202.246094 C 21.800781 202.246094 14.273438 198.878906 8.816406 193.421875 C 3.375 187.976562 0.00390625 180.445312 0.00390625 172.125 C 0 155.492188 13.484375 142.003906 30.128906 142.003906 L 142 142.003906 L 142 30.128906 C 142 13.488281 155.492188 0 172.128906 0 C 188.761719 0.00390625 202.238281 13.480469 202.25 30.117188 L 202.25 142.003906 L 314.128906 142.003906 C 330.765625 142.011719 344.230469 155.492188 344.238281 172.125 Z M 344.238281 172.125 " />
                {/* <path fill="rgba(226,226,226,0.1)" d="M 344.238281 172.125 C 344.238281 188.765625 330.753906 202.25 314.105469 202.25 L 202.242188 202.25 L 202.242188 314.121094 C 202.242188 330.765625 188.75 344.25 172.117188 344.242188 C 163.796875 344.242188 156.265625 340.878906 150.820312 335.425781 C 145.367188 329.972656 141.996094 322.445312 142 314.128906 L 141.992188 202.246094 L 30.113281 202.246094 C 21.800781 202.246094 14.273438 198.878906 8.816406 193.421875 C 3.375 187.976562 0.00390625 180.445312 0.00390625 172.125 C 0 155.492188 13.484375 142.003906 30.128906 142.003906 L 142 142.003906 L 142 30.128906 C 142 13.488281 155.492188 0 172.128906 0 C 188.761719 0.00390625 202.238281 13.480469 202.25 30.117188 L 202.25 142.003906 L 314.128906 142.003906 C 330.765625 142.011719 344.230469 155.492188 344.238281 172.125 Z M 344.238281 172.125 " /> */}
            </g>
        </svg>
    );
})

export const Minus = React.memo((props) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="svg" style={props.style} width="100%" height="100%" viewBox="0 0 344 344" stroke="transparent" strokeWidth="1">
            <filter id="inset-shadow">
                <feOffset dx='0' dy='-1' />
                <feGaussianBlur
                    stdDeviation='9'
                    result='offset-blur'
                />
                <feComposite
                    operator='out'
                    in='SourceGraphic'
                    in2='offset-blur'
                    result='inverse'
                />
                <feFlood
                    floodColor={props.insetColor}
                    floodOpacity='0.75'
                    result='color'
                />
                <feComposite
                    operator='in'
                    in='color'
                    in2='inverse'
                    result='shadow'
                />
                <feComposite
                    operator='over'
                    in='shadow'
                    in2='SourceGraphic'
                />
            </filter>
            <filter id="inset-shadow2">
                <feOffset dx='0' dy='0' />
                <feGaussianBlur
                    stdDeviation='24'
                    result='offset-blur'
                />
                <feComposite
                    operator='out'
                    in='SourceGraphic'
                    in2='offset-blur'
                    result='inverse'
                />
                <feFlood
                    floodColor={props.highlight}
                    floodOpacity={props.highlight === 'white' ? '0.2' : '0.8'}
                    result='color'
                />
                <feComposite
                    operator='in'
                    in='color'
                    in2='inverse'
                    result='shadow'
                />
                <feComposite
                    operator='over'
                    in='shadow'
                    in2='SourceGraphic'
                />
            </filter>
            {/* <clipPath id="plus-path" clipPathUnits="objectBoundingBox" transform="scale(0.002177 0.002177)">
                <path d="M459.319,229.668c0,22.201-17.992,40.193-40.205,40.193H269.85v149.271c0,22.207-17.998,40.199-40.196,40.193   c-11.101,0-21.149-4.492-28.416-11.763c-7.276-7.281-11.774-17.324-11.769-28.419l-0.006-149.288H40.181   c-11.094,0-21.134-4.492-28.416-11.774c-7.264-7.264-11.759-17.312-11.759-28.413C0,207.471,17.992,189.475,40.202,189.475h149.267   V40.202C189.469,17.998,207.471,0,229.671,0c22.192,0.006,40.178,17.986,40.19,40.187v149.288h149.282   C441.339,189.487,459.308,207.471,459.319,229.668z" />
            </clipPath> */}
            <g filter="url(#inset-shadow)" style={{isolation: 'isolate'}}>
                <path filter="url(#inset-shadow2)" d="M 344.234375 172.109375 C 344.234375 188.75 330.75 202.238281 314.101562 202.238281 L 30.113281 202.238281 C 21.800781 202.238281 14.269531 198.867188 8.816406 193.414062 C 3.367188 187.96875 0 180.4375 0 172.121094 C -0.00390625 155.480469 13.484375 141.992188 30.128906 141.996094 L 314.125 141.996094 C 330.757812 142 344.230469 155.480469 344.234375 172.109375 Z M 344.234375 172.109375 " />
                {/* <path fill="rgba(226,226,226,0.1)" d="M 344.234375 172.109375 C 344.234375 188.75 330.75 202.238281 314.101562 202.238281 L 30.113281 202.238281 C 21.800781 202.238281 14.269531 198.867188 8.816406 193.414062 C 3.367188 187.96875 0 180.4375 0 172.121094 C -0.00390625 155.480469 13.484375 141.992188 30.128906 141.996094 L 314.125 141.996094 C 330.757812 142 344.230469 155.480469 344.234375 172.109375 Z M 344.234375 172.109375 " /> */}
            </g>
        </svg>
    );
})

export const DoublePlus = React.memo((props) => {

    return (
        <div className="right">
            <Plus {...props} />
            <div style={{width: '2vw'}}/>
            <Plus {...props} />
        </div>
    );
})

export const DoubleMinus = React.memo((props) => {

    return (
        <div className="left">
            <Minus {...props} />
            <div style={{width: '2vw'}}/>
            <Minus {...props} />
        </div>
    );
})