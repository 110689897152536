import React, { useState, useRef, useEffect, useMemo, useCallback, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import BrightcoveVideoPlayer from './BrightcoveVideoPlayer'
import YoutubeVideoPlayer from './YoutubeVideoPlayer';
import { useIndexedDB } from 'react-indexed-db-hook';
// import seasiLogo from './resources/seasi-logo.jpg'
import seasiLogo from './resources/seasi-logo.png'
import pfmLogo from './resources/pfm-logo.png'
import vvtvLogo from './resources/vv-logo.png'
import ewLogo from './resources/ew-logo.png'
import background from './resources/black-bg.jpg'
import { useEventListener } from './hooks';
import EmbeddedSlider from './EmbeddedSlider';
import EmojiFeedbackDial from "./EmojiFeedbackDial";
import config from './config.json'
import TicktBoxVideoPlayer from './TicktBoxVideoPlayer';
import { AspectRatio } from './AspectRatioContext';
import ButtonOnlyView from './ButtonOnlyView';

const PREFIX = 'ContentPresenter';

const classes = {
    root: `${PREFIX}-root`,
    main: `${PREFIX}-main`,
    background: `${PREFIX}-background`,
    logoBox: `${PREFIX}-logoBox`,
    teamLogo: `${PREFIX}-teamLogo`,
    headerLogo: `${PREFIX}-headerLogo`,
    card: `${PREFIX}-card`,
    continue: `${PREFIX}-continue`,
    continueBack: `${PREFIX}-continueBack`,
    appbar: `${PREFIX}-appbar`,
    fixedHeader: `${PREFIX}-fixedHeader`,
    fixedFooter: `${PREFIX}-fixedFooter`,
    white: `${PREFIX}-white`,
    titleBox: `${PREFIX}-titleBox`,
    margin: `${PREFIX}-margin`,
    buttonRow: `${PREFIX}-buttonRow`,
    buttonBox: `${PREFIX}-buttonBox`,
    buttonProgress: `${PREFIX}-buttonProgress`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: '-webkit-center',
    [`& .${classes.main}`]: {
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        // background: `url(${background})`,
        // backgroundSize: 'cover',
        // background: 'rgba(21,27,23)',
        background: 'black',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1
    },
    [`& .${classes.background}`]: {
        background: `url(${background})`,
        // background: 'rgba(21,27,23)',
        backgroundSize: 'cover',
        flex: 1
    },
    [`& .${classes.logoBox}`]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    [`& .${classes.teamLogo}`]: {
        height: '100%',
        maxHeight: '150px'
    },
    [`& .${classes.headerLogo}`]: {
        height: '100%',
        maxHeight: '80px'
    },
    [`& .${classes.card}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '400px',
        maxWidth: '800px',
        minHeight: '300px'
    },
    [`& .${classes.continue}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        zIndex: 1002,
    },
    [`& .${classes.continueBack}`]: {
        zIndex: 1001,
        position: 'absolute',
        height: '100%',
        width: '100%',
        backdropFilter: 'blur(12px)'
    },
    [`& .${classes.appbar}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem 2rem 1rem 2rem',
        boxSizing: 'border-box',
        zIndex: 1000
    },
    [`& .${classes.fixedHeader}`]: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.fixedFooter}`]: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.white}`]: {
        color: 'white'
    },
    [`& .${classes.titleBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    [`& .${classes.margin}`]: {
        margin: '1rem'
    },
    [`& .${classes.buttonRow}`]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly'
    },
    [`& .${classes.buttonBox}`]: {
        position: 'relative'
    },
    [`& .${classes.buttonProgress}`]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        margin: 'auto',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    },
    // video: {
    //     position: 'absolute',
    //     height: '100vh',
    //     width: '100vw',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     top: 0,
    //     left: 0,
    //     backgroundColor: '#151515'
    // },
});

const short = require('short-uuid');

const logos = {
    'SEA': seasiLogo,
    'PFM': pfmLogo,
    'VTV': vvtvLogo,
    'EWX': ewLogo,
    'CPT': seasiLogo,
}

const getYoutubeLiveStatus = async (videoId, token) => {
    try {
        const res = await fetch(`https://${config.rest.sessionGuestAPI}/video/youtube/live-status?videoId=${videoId}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const data = await res.json()
        return data
    } catch (err) {
        console.log(err)
        return {}
    }
}

const getTicktBoxStream = async (videoId, drmType, token) => {
    try {
        const res = await fetch(`https://${config.rest.ticktboxVideoAPI}/stream?videoId=${videoId}&drmType=${drmType}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        const data = await res.json()
        return data
    } catch (err) {
        console.log(err)
        return {}
    }
}

const ContentPresenter = React.memo(({
    token,
    sessionId,
    userId,
    videoPlatform,
    playerId,
    videoId,
    multiSection,
    videoIndex,
    techCheck,
    fadeOut,
    autoStop,
    videoStopTime,
    wm,
    wmOpacity,
    watermarkStyle,
    alreadyStarted,
    sessionStartTime,
    videoTime,
    videoDuration,
    unloadTime,
    isLive,
    allowFullscreen,
    isFullscreen,
    toggleFullScreen,
    videoDataRef,
    handleVideoStart,
    handleVideoError,
    handleUnload,
    handleRedirect,
    // handleNotWatching,
    hasOnScreenDial,
    hasEmojiDial,
    hasOnlyButtons,
    maxScore,
    dialMotion,
    numberOfMeters,
    sliderKnob,
    sliderKnobColor,
    includeTuneOut,
    includeShoutOuts,
    includeBreaks,
    maxBreaks,
    limitTuneOut,
    startTuneOutTime,
    stopTuneOutTime,
    inactiveReminder,
    inactiveInterval,
    inactiveMessage,
    noDialArrowKeys,
    sendData,
    resetDial,
    handleStartDial,
    handlePauseDial,
    handleScoreChange,
    symbolOptions,
    inputTime,
    breakCount,
    setBreakCount
}) => {

    // const navigate = useNavigate()

    // const [ratio, setRatio] = useState()
    const [showDial, setShowDial] = useState(false)
    // const [sendDialData, setSendDialData] = useState(false)
    const [buttonReasonScore, setButtonReasonScore] = useState()
    const [showButton, setShowButton] = useState({
        'R': !(limitTuneOut && !!startTuneOutTime)
    })

    const { ratio, setRatio } = useContext(AspectRatio)

    const { getAll, clear, add } = useIndexedDB('scores');

    // const { isOnline } = useIsOnline(token)

    const playerRef = useRef()
    // const unloadRef = useRef(true)
    const videoTimeRef = useRef(videoTime)
    // const visiblityRef = useRef(document[hidden])
    // const dialMessage = useRef()
    // dialMessageRef.current = dialMessage.current
    // const hasConnected = useRef()
    // hasConnected.current = connectedDial
    // const onlineRef = useRef(true)
    const showButtonRef = useRef(showButton)
    const buttonShowTriggered = useRef({})

    // const useDial = isDialSession && role === 'Viewer'

    useEffect(() => {
        showButtonRef.current = showButton
    }, [showButton])

    useEffect(() => {
        if (ratio) setShowDial(true)
    }, [ratio])

    const getVideoPosition = useCallback(() => {
        return playerRef.current && (playerRef.current.currentTime ? playerRef.current.currentTime() : playerRef.current.getCurrentTime())
    }, [])

    const getPlaylist = useCallback(() => {
        return playerRef.current && playerRef.current.playlist()
    }, [])

    useEffect(() => {
        videoDataRef.current = {
            getVideoPosition,
            getPlaylist
        }
        return () => {
            videoDataRef.current = null
        }
    }, [videoDataRef, getVideoPosition, getPlaylist])

    const setVideoAspectRatio = useCallback((aspectRatio) => {
        setRatio(aspectRatio)
    }, [setRatio])

    const youtubeLiveStatus = useCallback(async (vid) => {
        const status = await getYoutubeLiveStatus(vid, token)
        console.log('LIVE VIDEO STATUS', status)
        return status
    }, [token])

    const videoStreamData = useCallback(async (drmType) => {
        const status = await getTicktBoxStream(videoId, drmType, token)
        console.log('GOT TB STREAM DATA', status)
        return status
    }, [videoId, token])

    const onVideoTimeChange = useCallback((event) => {
        if (limitTuneOut && (startTuneOutTime || stopTuneOutTime)) {
            const time = event.target.player ? event.target.player.currentTime() * 1000 : event.target.currentTime * 1000;
            if (showButtonRef.current['R'] && stopTuneOutTime && (videoDuration - (stopTuneOutTime * 60000) <= time)) {
                setShowButton(prev => ({ ...prev, 'R': false }))
            } else if (!(showButtonRef.current['R'] || buttonShowTriggered.current['R']) && startTuneOutTime && (startTuneOutTime * 60000 <= time)) {
                buttonShowTriggered.current = { ...buttonShowTriggered.current, 'R': true }
                setShowButton(prev => ({ ...prev, 'R': true }))
            }
        }
    }, [videoDuration, limitTuneOut, startTuneOutTime, stopTuneOutTime]);

    const VideoPlayer = useMemo(() => {
        switch (videoPlatform) {
            case 'Brightcove':
            default:
                return BrightcoveVideoPlayer
            case 'YouTube':
                return YoutubeVideoPlayer
            case 'ticktBox':
                return TicktBoxVideoPlayer
            // case 'Twitch': 
            //     break;
        }
    }, [videoPlatform])

    return (
        (<Root>
            <div style={{
                position: 'relative',
                flex: '1',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: hasOnScreenDial || hasEmojiDial ? 'flex-end' : 'center',

            }}>
                <VideoPlayer
                    videoRef={playerRef}
                    style={{
                        maxWidth: ratio ? `${1 / ratio * 100}vh` : 0,
                        maxHeight: ratio ? `${ratio * 100}vw` : 0,
                        width: ratio ? '100%' : 0,
                        height: ratio ? '100%' : 0,
                        flex: 1
                    }}
                    fluid={false}
                    fill={true}
                    fullscreen={false}
                    onMetadataLoaded={setVideoAspectRatio}
                    onBuffer={handlePauseDial}
                    onPlay={handleStartDial}
                    onStarted={handleVideoStart}
                    // onVisiblityChange={handleNotWatching}
                    onUnload={handleUnload}
                    onVideoError={handleVideoError}
                    onTimeUpdate={limitTuneOut ? onVideoTimeChange : undefined}
                    techCheck={techCheck}
                    fadeOut={fadeOut}
                    autoStop={autoStop}
                    videoStopTime={videoStopTime}
                    wm={wm}
                    wmOpacity={wmOpacity}
                    watermarkStyle={watermarkStyle}
                    playerId={playerId}
                    videoId={videoId}
                    multiSection={multiSection}
                    videoIndex={videoIndex}
                    sessionId={sessionId}
                    alreadyStarted={alreadyStarted}
                    sessionStartTime={sessionStartTime}
                    videoTime={videoTime}
                    videoDuration={videoDuration}
                    unloadTime={unloadTime}
                    onFinished={handleRedirect}
                    isLive={isLive}
                    liveStreamStatus={youtubeLiveStatus}
                    videoStreamData={videoStreamData}
                    allowFullscreen={allowFullscreen}
                    isFullscreen={isFullscreen}
                    toggleFullScreen={toggleFullScreen}
                    hasOnScreenDial={hasOnScreenDial}
                    hasEmojiDial={hasEmojiDial}
                />
            </div>
            {hasOnScreenDial && <EmbeddedSlider
                sendData={sendData}
                sessionId={sessionId}
                userId={userId}
                techCheck={techCheck}
                videoIndex={videoIndex}
                onScoreChange={handleScoreChange}
                includeShoutOuts={includeShoutOuts}
                includeBreaks={includeBreaks}
                maxBreaks={maxBreaks}
                breakCount={breakCount}
                setBreakCount={setBreakCount}
                show={showDial}
                viewRatio={ratio}
                maxScore={maxScore}
                dialMotion={dialMotion}
                numberOfMeters={numberOfMeters}
                sliderKnob={sliderKnob}
                sliderKnobColor={sliderKnobColor}
                includeTuneOut={showButton['R'] && includeTuneOut}
                inactiveReminder={inactiveReminder}
                inactiveInterval={inactiveInterval}
                inactiveMessage={inactiveMessage}
                reset={resetDial}
                noDialArrowKeys={noDialArrowKeys}
            />}
            {hasEmojiDial && <EmojiFeedbackDial
                sendData={sendData}
                sessionId={sessionId}
                userId={userId}
                techCheck={techCheck}
                videoIndex={videoIndex}
                includeShoutOuts={includeShoutOuts}
                includeBreaks={includeBreaks}
                maxBreaks={maxBreaks}
                breakCount={breakCount}
                setBreakCount={setBreakCount}
                show={showDial}
                includeTuneOut={showButton['R'] && includeTuneOut}
                onScoreChange={handleScoreChange}
                viewRatio={ratio}
                symbolOptions={symbolOptions}
                inputTime={inputTime}
                inactiveReminder={inactiveReminder}
                inactiveInterval={inactiveInterval}
                inactiveMessage={inactiveMessage}
            />}
            {hasOnlyButtons && <ButtonOnlyView
                sendData={sendData}
                sessionId={sessionId}
                userId={userId}
                techCheck={techCheck}
                videoIndex={videoIndex}
                includeBreaks={includeBreaks}
                maxBreaks={maxBreaks}
                show={showDial}
                includeShoutOuts={includeShoutOuts}
                breakCount={breakCount}
                setBreakCount={setBreakCount}
                includeTuneOut={true}
                onScoreChange={handleScoreChange}
                viewRatio={ratio}
                inactiveReminder={inactiveReminder}
                inactiveInterval={inactiveInterval}
                inactiveMessage={inactiveMessage}
            />}
        </Root>)
    );
    // }
})

export default ContentPresenter;
