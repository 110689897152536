import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
// import { makeStyles } from '@mui/material/styles';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Typography } from '@mui/material';
import KeyboardEventHandler from 'react-keyboard-event-handler'
import * as workerTimers from 'worker-timers'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

const ButtonReasonDialog = React.memo(({ scoreData, maxLength = 256, maxTime = 45, timeoutMessage = true, onSubmit, onCancel, dialMode, isDialSession, feedbackWindowBottomRight }) => {

    const [buttonPressReason, setButtonPressReason] = useState("")
    const [countdown, setCountdown] = useState(maxTime ? maxTime * 1000 : undefined)
    const [timedOut, setTimedOut] = useState(false)
    const [lastBtn, setLastBtn] = useState();
    
    const timeRef = useRef()
    const timerRef = useRef()
    const timeoutRef = useRef()
    const { t } = useTranslation('session_video');
    // const timeoutRef = useRef((timeoutMessage ? 3 : 0))

    // const buttonReasonRef = useRef()
    const texts = useMemo(() => {
        let btn = scoreData?.button || lastBtn;
        if (btn) {
            let strings = {}
            if (btn === 'R') {
                strings.title = t('why_tune_out');
                strings.timeOut = isDialSession ? dialMode === 'symbol' ? t('thanks_continue_to_press_symbol') : t('thanks_continue_to_move_dial') : ''
            } else if (btn === 'B-S') {
                strings.title = t("before_break");
                strings.timeOut = t('enjoy_break')
            } else if (btn === 'S') {
                strings.title = t('please_express');
                strings.timeOut = t("thank_thoughts")
            }
            return strings;
        }
    }, [scoreData, isDialSession, dialMode, lastBtn])

    const handleButtonReasonChange = useCallback((event) => {
        const newVal = (event.target.value || "").replace(/\r?\n|\r/g, "")
        setButtonPressReason(newVal)
    }, [])

    const sendButtonPressReason = useCallback(() => {
        console.log('sending button press reason')
        setLastBtn(scoreData?.button);
        const newScoreData = { ...scoreData, buttonPressReason }
        onSubmit(newScoreData)
    }, [scoreData, buttonPressReason, onSubmit, setLastBtn])

    useEffect(() => {
        if (scoreData && maxTime) {
            timeRef.current = moment().add(maxTime * 1000, 'ms')
            setCountdown(maxTime * 1000)
        } else if (!scoreData) {
            setButtonPressReason("")
            setTimedOut(false);
        }
        return () => {
            console.log('unmounting countdown...')
            if (timerRef.current) workerTimers.clearTimeout(timerRef.current)
            if (timeoutRef.current) workerTimers.clearTimeout(timeoutRef.current)
            timerRef.current = null;
            timeoutRef.current = null;
        }
    }, [scoreData, maxTime])

    useEffect(() => {
        if (!!scoreData && countdown !== null && countdown !== undefined) {
            const timeDiff = timeRef.current.diff(moment()) //+ offsetRef.current
            if (timeDiff <= 0) {
                workerTimers.clearTimeout(timerRef.current)
                timerRef.current = null
                setTimedOut(timeoutMessage)
                timerRef.current = workerTimers.setTimeout(() => {
                    timerRef.current = null
                    sendButtonPressReason()
                }, timeoutMessage ? 3000 : 0)
            } else if (timeDiff > 0) {
                const tts = timeDiff % 3600000 % 60000 % 1000
                timerRef.current = workerTimers.setTimeout(() => {
                    setCountdown(timeDiff)
                }, tts)
            }

        }
    }, [scoreData, timeoutMessage, countdown, sendButtonPressReason])

    return (
        <>
            <KeyboardEventHandler
                isExclusive={true}
                isDisabled={!scoreData}
                handleFocusableElements={true}
                handleKeys={["function", "meta", "ctrl", "alt", "ctrl+c", "ctrl+shift+i"]}
                onKeyEvent={(key, event) => {
                    event.preventDefault()
                    console.log(key, event)
                }} />
            <Dialog disablePortal style={feedbackWindowBottomRight ? {display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end'} : {}} open={!!scoreData}>
                <DialogTitle>{texts?.title}</DialogTitle>
                <DialogContent>
                    {timedOut ? <Typography variant="body2" display="block">
                        {texts?.timeOut}
                    </Typography> : <>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="text"
                            fullWidth
                            multiline
                            minRows={4}
                            inputProps={{ maxLength }}
                            value={buttonPressReason}
                            onChange={handleButtonReasonChange}
                        // inputRef={buttonReasonRef}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            {countdown && <Typography variant="subtitle2" display="block" style={{ flex: 1, color: countdown <= 10000 ? 'red' : 'black', fontWeight: countdown <= 10000 ? 'bold' : 'normal' }}>{`${Math.floor((countdown % 3600000) % 60000 / 1000)}`} {t('seconds_remaining_text')}</Typography>}
                            <Typography variant="caption" display="block" style={{ flex: 1, textAlign: 'right' }}>{buttonPressReason.length}/{maxLength}</Typography>
                        </div>
                    </>}
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' disabled={!buttonPressReason} onClick={sendButtonPressReason}>
                        {timedOut ? t('continue_text') : t('submit_text')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

})

export default ButtonReasonDialog;