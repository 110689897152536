import React, { useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import MuiAlert from '@mui/lab/Alert';
import { useLocalStorage } from './hooks';
// import * as workerTimers from 'worker-timers';
import { useTranslation } from 'react-i18next';

const PREFIX = 'ButtonOnlyView';

const classes = {
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    sliderBox: `${PREFIX}-sliderBox`,
    sliderButtons: `${PREFIX}-sliderButtons`,
    button: `${PREFIX}-button`,
    alert: `${PREFIX}-alert`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ratio}) => ({
    [`& .${classes.root}`]: {
        // position: 'absolute',
        // top: 0,
        // width: '100vw',
        // height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1001,
        overflow: 'hidden'
    },
    [`& .${classes.container}`]: {
        // position: 'fixed',
        bottom: 0,
        width: '100%',
        // height: ratio ? `${ratio * 100}vw` : '100%',
        flex: 1,
        minHeight: '100px',
        maxHeight: ratio ? `calc((100vh - ${ratio * 100}vw) / 2)` : '100%',
        // maxHeight: '100%',
        // maxWidth: ratio ? `${1/ratio * 100}vh` : '100%',
        // margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        // opacity: 0.45,
        filter: 'brightness(1.75) drop-shadow(0px 0px 1px white)',
        border: '1px solid transparent',
        // paddingBottom: '14px',
        boxSizing: 'border-box'
    },
    [`& .${classes.sliderBox}`]: {
        width: '70%',
    },
    [`& .${classes.sliderButtons}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '12px',
        marginBottom: '5px'
    },
    [`& .${classes.button}`]: {
        margin: '0 5px 0 5px',
        '&.MuiButton-contained.Mui-disabled': {
            color: 'rgba(255,255,255, .2) !important'
        }
    },
    [`& .${classes.alert}`]: {
        position: 'absolute',
        top: 0,
        padding: '10px',
        transform: 'translateY(-100%)'
    }
}));



const ButtonOnlyView = React.memo(({ sessionId, userId, techCheck, videoIndex, sendData, show, viewRatio, onScoreChange, includeTuneOut, includeShoutOuts, includeBreaks, breakCount, maxBreaks, setBreakCount }) => {

    const [buttonPress, setButtonPress] = useLocalStorage(`tuneinout${sessionId}-${videoIndex || 0}-${techCheck ? '-TC' : ''}`, undefined);

    const { t } = useTranslation('buttons');

    const onChange = useRef()
    onChange.current = onScoreChange


    const handleTuneOut = useCallback((event) => {
        event.currentTarget.blur()
        if ((sendData || techCheck) && buttonPress !== 'R'){
            setButtonPress('R')
            onChange.current({
                button: 'R',
                timestamp: Date.now(),
                registrationId: `${userId}`,
                sessionId: `${sessionId}`,
            })
        }
    }, [sendData, techCheck, buttonPress, setButtonPress, sessionId, userId])

    const handleShoutOut = useCallback((event) => {
        event.currentTarget.blur();
        onChange.current({
            button: 'S',
            timestamp: Date.now(),
            registrationId: `${userId}`,
            sessionId: `${sessionId}`,
        })
    }, [sessionId, userId])

    const handleBreak = useCallback((event) => {
        event.currentTarget.blur();
        onChange.current({
            button: 'B-S',
            timestamp: Date.now(),
            registrationId: `${userId}`,
            sessionId: `${sessionId}`,
        })
        setBreakCount(prev => prev++);
    }, [sessionId, userId, setBreakCount])

    return show && <Root ratio={viewRatio}>
        <div className={classes.container}>
            <div className={classes.sliderBox}>
                {(includeTuneOut || includeShoutOuts || includeBreaks) && <div className={classes.sliderButtons}>
                    {includeTuneOut && <Button className={classes.button} variant="contained" size="small" color="primary" disabled={!((sendData && buttonPress !== 'R') || techCheck)} style={{
                        border: `1px solid rgba(255,255,255,${buttonPress === 'R' ? '0.85' : '0.15'})`,
                        // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                        backgroundColor: '#9d3b3a',
                        // filter: !(sendData || techCheck) ? 'grayscale(1)' : `contrast(${buttonPress === 'R' ? '2.5' : '1'}) grayscale(${buttonPress === 'R' ? '0' : '0.35'})`
                    }} onClick={handleTuneOut}>{t('TUNE_OUT_button')}</Button>}
                    {includeBreaks && <Button className={classes.button} variant="contained" size="small" color="primary" disabled={!(breakCount < maxBreaks)} style={{
                        border: `1px solid rgba(255,255,255,${!(breakCount < maxBreaks) ? '0.85' : '0.15'})`,
                        // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                        backgroundColor: 'rgba(255, 255, 0, 0.5)',
                        // filter: `contrast(${!(breakCount < maxBreaks) ? '2.5' : '1'}) grayscale(${!(breakCount < maxBreaks) ? '0' : '0.35'})`
                    }} onClick={handleBreak}>{t('BREAK_btn')}</Button>}
                    {includeShoutOuts && <Button className={classes.button} variant="contained" size="small" color="primary" style={{
                        border: `1px solid rgba(255,255,255, 0.15)`,
                        // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                        backgroundColor: 'rgba(59, 175, 44, 0.42)',
                        // filter: `contrast(${buttonPress === 'R' ? '2.5' : '1'}) grayscale(${buttonPress === 'R' ? '0' : '0.35'})`
                    }} onClick={handleShoutOut}>{t('COMMENT_btn')}</Button>}
                    {/* <Button className={classes.button} variant="contained" size="small" color="primary" style={{
                        border: `1px solid rgba(255,255,255,${buttonPress === 'G' ? '0.85' : '0.15'})`,
                        // backgroundColor: buttonPress === 'G' ? '#3BB273' : '#297c50',
                        backgroundColor: '#297c50',
                        filter: `contrast(${buttonPress === 'G' ? '2.5' : '1'}) grayscale(${buttonPress === 'G' ? '0' : '0.35'})`
                    }} onClick={() => {
                        if (sendData && buttonPress !== 'G') setButtonPress('G')
                    }}>TUNE IN</Button> */}
                </div>}
            </div>
        </div>
    </Root>;
})


export default ButtonOnlyView;
