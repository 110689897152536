import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import BrandedPage from './BrandedPage'
// import LoadingPage from './LoadingPage';
import SessionInfoBanner from './SessionInfoBanner';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const PREFIX = 'EventFullPage';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`
};

const Root = styled('div')({
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    [`& .${classes.root}`]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    }
});

const EventFullPage = React.memo(() => {
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;


    const { t } = useTranslation('self_tech_check_step_6');

    const { sessionTeam, sessionDate, videoTitle } = state

    useEffect(() => {
        if (!sessionTeam || !sessionDate || !videoTitle) {
            navigate('/')
        }
    }, [navigate, sessionTeam, sessionDate, videoTitle])

    return (
        <Root>
            <div className={classes.root}>
                <BrandedPage team={sessionTeam}>
                    <SessionInfoBanner videoTitle={videoTitle} sessionDate={sessionDate} />
                    <div className={classes.container}>
                        <Typography variant="h4">{t('were_full')}</Typography>
                        <br />
                        <Typography variant="h6">
                            {t('thanks_but_spots_taken')}
                        </Typography>
                    </div>
                </BrandedPage>
            </div>
        </Root>
    );
})

export default EventFullPage;
