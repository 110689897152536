import React, { useState, useRef, useEffect, useCallback } from 'react';
// import { makeStyles } from '@mui/material/styles';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Typography } from '@mui/material';
import KeyboardEventHandler from 'react-keyboard-event-handler'
import * as workerTimers from 'worker-timers'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

const BreakDialog = React.memo(({ onSubmit, breakCount, setBreakCount, breakDataDialog, setBreakDataDialog, videoDataRef, feedbackWindowBottomRight }) => {

    const { t } = useTranslation('session_video');

    const sendButtonPressReason = useCallback(() => {
        const newScoreData = { ...breakDataDialog, button: 'B-E', buttonCount: breakCount + 1, timestamp: Date.now(), videoTime: videoDataRef.current.getVideoPosition() }
        setBreakCount(prev => prev + 1);
        onSubmit(newScoreData)
        setBreakDataDialog();
    }, [onSubmit, breakCount, breakDataDialog, videoDataRef])

    return (
        <>
            <KeyboardEventHandler
                isExclusive={true}
                isDisabled={!breakDataDialog}
                handleFocusableElements={true}
                handleKeys={["function", "meta", "ctrl", "alt", "ctrl+c", "ctrl+shift+i"]}
                onKeyEvent={(key, event) => {
                    event.preventDefault()
                    console.log(key, event)
                }} />
            <Dialog style={feedbackWindowBottomRight ? {display: 'flex', alignSelf: 'flex-end', justifyContent: 'flex-end'} : {}} disablePortal open={!!breakDataDialog && breakDataDialog.button === 'B-S'}>
                <DialogTitle>{t('reminder_return_in_few_minutes')}</DialogTitle>
                < DialogContent>
                    <Typography variant="body2" display="block">
                        {/* Break #{breakCount}  */}
                        {t('click_end_break')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='contained' onClick={sendButtonPressReason}>
                        {t('end_break_btn')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

})

export default BreakDialog;