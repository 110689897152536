import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/material/styles';
import queryString from 'query-string';
import LoadingPage from './LoadingPage';
import config from './config.json'
import ThemeContext from './ThemeContext';
import BrandedPage from './BrandedPage';
import SessionInfoBanner from './SessionInfoBanner';
import TechCheckScheduler from './TechCheckScheduler';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const PREFIX = 'RegistrationPage';

const classes = {
    fullscreen: `${PREFIX}-fullscreen`,
    root: `${PREFIX}-root`,
    headerLogo: `${PREFIX}-headerLogo`,
    card: `${PREFIX}-card`,
    fixedHeader: `${PREFIX}-fixedHeader`,
    fixedFooter: `${PREFIX}-fixedFooter`,
    white: `${PREFIX}-white`,
    titleBox: `${PREFIX}-titleBox`,
    content: `${PREFIX}-content`,
    container: `${PREFIX}-container`,
    spacing: `${PREFIX}-spacing`,
    select: `${PREFIX}-select`,
    loading: `${PREFIX}-loading`,
    blank: `${PREFIX}-blank`,
    submit: `${PREFIX}-submit`,
    buttonProgress: `${PREFIX}-buttonProgress`
};

const Root = styled('div')({
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    [`& .${classes.root}`]: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    // root: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     height: '100%',
    //     width: '100%',
    //     // background: '#222'
    //     background: `url(${background})`,
    //     backgroundSize: 'cover'
    // },
    [`& .${classes.headerLogo}`]: {
        height: '100%',
        maxHeight: '80px'
    },
    [`& .${classes.card}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        width: '55%',
        minWidth: '300px',
        maxWidth: '800px'
    },
    [`& .${classes.fixedHeader}`]: {
        position: 'static',
        top: 0,
        width: '100%',
        height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.fixedFooter}`]: {
        position: 'static',
        bottom: 0,
        width: '100%',
        // height: '5rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        padding: '1rem',
        // padding: '1em 2em 1em 2em',
        boxSizing: 'border-box',
        background: '#0f161c',
        // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    },
    [`& .${classes.white}`]: {
        color: 'white'
    },
    [`& .${classes.titleBox}`]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem'
    },
    [`& .${classes.content}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        height: '100%',
        width: '95%',
        // minWidth: '300px',
        // maxWidth: '800px',
        color: 'white',
        position: 'relative'
    },
    [`& .${classes.container}`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '1rem 2rem 2rem 2rem',
        textAlign: 'center',
        // marginTop: 'auto',
        minHeight: '68%',
        height: 'auto',
        marginBottom: 'auto'
        // width: '55%',
        // minWidth: '400px',
        // maxWidth: '800px',
        // minHeight: '300px'
    },
    [`& .${classes.spacing}`]: {
        width: '100%',
        marginTop: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    [`& .${classes.select}`]: {
        margin: '0.25rem 0 0.25rem 0',
        backgroundColor: 'white'
    },
    [`& .${classes.loading}`]: {
        margin: '0.25rem 0 0.25rem 0',
        // width: 'calc(37px + 1.1876em)',
        // height: 'calc(37px + 1.1876em)'
    },
    [`& .${classes.blank}`]: {
        marginTop: 'calc(37px + 1.1876em)'
    },
    [`& .${classes.submit}`]: {
        position: 'relative',
        // alignSelf: 'flex-end',
        marginTop: '1rem'
    },
    [`& .${classes.buttonProgress}`]: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});

const registerGuestPromise = (sessionId, screenerId, responseId, forceSchedule) =>
    fetch(`https://${config.rest.sessionGuestAPI}/register`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId, screenerId, responseId, forceSchedule })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const acuityCalendarDatesPromise = (calendarId, typeId, selectedDate, timezone, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/acuity/dates?typeId=${typeId}&calendarId=${calendarId}&selectedDate=${selectedDate}&timezone=${timezone}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const acuityCalendarDateTimesPromise = (calendarId, typeId, selectedDate, timezone, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/acuity/times?typeId=${typeId}&calendarId=${calendarId}&selectedDate=${selectedDate}&timezone=${timezone}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const scheduleTechCheckPromise = (guestId, calendarId, typeId, datetime, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/tech-check/${guestId}/schedule`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ calendarId, typeId, datetime })

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const resendVerificationPromise = (guestId, sessionId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/verify/${guestId}/resend`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify({ sessionId })
    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const startTechCheckPromise = (guestId, token) =>
    fetch(`https://${config.rest.sessionGuestAPI}/tech-check/${guestId}/start`, {
        method: 'POST',
        mode: 'cors',
        // credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },

    }).then(response => {
        if (response.status >= 200 && response.status <= 299) {
            return response.json();
        } else {
            return response.json().then(res => {
                throw res
            })
        }
    }).then(data => {
        return data
    })

const RegistrationPage = React.memo(() => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search, state } = location;
    const { sessionId } = useParams();

    const { setTeam } = useContext(ThemeContext);

    const [loading, setLoading] = useState(true)
    const [resending, setResending] = useState(false)
    const [error, setError] = useState()
    const [videoTitle, setVideoTitle] = useState()
    const [checkInDate, setCheckInDate] = useState()
    const [sessionTeam, setSessionTeam] = useState()
    const [qualtricsPreview, setQualtricsPreview] = useState()
    const [alreadyApproved, setAlreadyApproved] = useState()
    const [autoTechCheck, setAutoTechCheck] = useState()
    const [scheduledDateTime, setScheduledDateTime] = useState()


    const guestId = useRef()
    const acuityData = useRef()
    const token = useRef()

    const { qid = null, rid } = queryString.parse(search);
    const forceSchedule = !!state?.force
    const { t } = useTranslation('registration_page');

    useEffect(() => {
        if (!sessionId || !rid) {
            navigate('/')
        } else {
            registerGuestPromise(sessionId, qid, rid, forceSchedule).then((res) => {
                console.log(res)
                if (res.full) {
                    navigate(`/full`, { state: { sessionTeam: res.team, sessionDate: res.guestCheckInTime, videoTitle: res.videoTitle } })
                } if (res.autoTechCheck && res.emailVerified && !res.autoDenied) {
                    if (res.state === "Qualified" && res.encryptedId) {
                        navigate(`/verify/${res.encryptedId}`, { state: { id: res.token } })
                    } else {
                        startTechCheckPromise(res.id, res.token).then((g) => {
                            navigate(`/techcheck/${g.id}`, { state: { id: res.token } })
                        }).catch((e) => {
                            console.log(e)
                            navigate('/')
                        })
                    }
                } else {
                    setTeam(res.team)
                    setSessionTeam(res.team)
                    setCheckInDate(res.guestCheckInTime)
                    setVideoTitle(res.videoTitle)
                    setQualtricsPreview(res.qualtricsPreview)
                    setAlreadyApproved(res.state === "Approved")
                    setAutoTechCheck(res.autoTechCheck && !res.autoDenied)
                    setScheduledDateTime(res.techCheckScheduledTime)
                    guestId.current = res.id
                    acuityData.current = {
                        calendarId: res.acuityCalendarId,
                        appointmentTypeId: res.acuityAppointmentTypeId,
                        baseDate: res.scheduledStartTime
                    }
                    token.current = res.token
                    setLoading(false)
                }
            }).catch((e) => {
                console.log(e)
                navigate('/')
            })
        }
    }, [navigate, sessionId, qid, rid, forceSchedule])

    const getAcuityCalendarDates = useCallback((timezone) => {
        return acuityCalendarDatesPromise(acuityData.current.calendarId, acuityData.current.appointmentTypeId, acuityData.current.baseDate, timezone, token.current)
    }, [])

    const getAcuityCalendarDateTimes = useCallback((selectedDate, timezone) => {
        return acuityCalendarDateTimesPromise(acuityData.current.calendarId, acuityData.current.appointmentTypeId, selectedDate, timezone, token.current)
    }, [])

    const scheduleTechCheck = useCallback((selectedTime) => {
        return scheduleTechCheckPromise(guestId.current, acuityData.current.calendarId, acuityData.current.appointmentTypeId, selectedTime, token.current)
    }, [])

    const handleResend = useCallback(() => {
        setResending(true)
        setError()
        const resend = async (retry) => resendVerificationPromise(guestId.current, sessionId, token.current).then((resp) => {
            console.log(resp)
        }).catch((err) => {
            if (!retry) {
                console.log('ERROR RESENDING - TRYING AGAIN', err)
                return registerGuestPromise(sessionId, qid, rid, forceSchedule).then((res) => {
                    guestId.current = res.id
                    token.current = res.token
                    return resend(true)
                })
            } else {
                console.log('ERROR RESENDING', err)
                setError(err)
            }
        }).finally(() => {
            setResending(false)
        })
        resend()
        // resendVerificationPromise(guestId.current, token.current).then((resp) => {
        //     console.log(resp)
        // }).catch((err) => {
        //     console.log('ERROR RESENDING - TRYING AGAIN', err)
        //     registerGuestPromise(sessionId, qid, rid).then((res) => {
        //         guestId.current = res.id
        //         token.current = res.token
        //         resendVerificationPromise(guestId.current, token.current).then((resp) => {
        //             console.log(resp)
        //         }).catch((err) => {
        //             console.log('ERROR RESENDING', err)
        //             setError(err)
        //         })
        //     })
        // }).finally(() => {
        //     setResending(false)
        // })
    }, [sessionId, qid, rid, forceSchedule])

    return (
        <Root>
            <div className={classes.root}>
                {loading && <LoadingPage />}
                {!loading && <BrandedPage team={sessionTeam}>
                    <SessionInfoBanner videoTitle={videoTitle} sessionDate={checkInDate} />
                    <div className={classes.container}>
                        {alreadyApproved ? <>
                            <div className={classes.titleBox}>
                                <Typography variant="h4">{t('congrats')}</Typography>
                                <br />
                                <Typography variant="h6">{t('approved_see_you_on')}&nbsp;
                                    <span className={classes.bold}>{new Date(checkInDate).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hourCycle: 'h12', timeZoneName: 'short' })}</span>.</Typography>
                                {/* <Typography variant="h6">You should have received a separate confirmation email with the details to access the study on the day of the event. Please be sure to also check any SPAM/JUNK folders.</Typography> */}
                            </div>
                        </> : autoTechCheck ? <>
                            <div className={classes.titleBox}>
                                <Typography variant="h4">{t('eligible_for_self_guided_text')}</Typography>
                                <br />
                                <Typography variant="h6">{t('congrats_for_qualifying_for_self_guided')}</Typography>
                            </div>
                            <div className={classes.submit}>
                                <Button color='primary' variant='contained' disabled={resending} onClick={handleResend}>{t('resend_verification_email_button')}</Button>
                                {resending && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            {error && <Typography variant="subtitle2" color="error">{t('error_resending_verification_email')}</Typography>}
                        </> : <>
                            <TechCheckScheduler
                                qualtricsPreview={qualtricsPreview}
                                techCheckDateTime={scheduledDateTime}
                                getAcuityCalendarDates={getAcuityCalendarDates}
                                getAcuityCalendarDateTimes={getAcuityCalendarDateTimes}
                                scheduleTechCheck={scheduleTechCheck}
                            />
                        </>}
                    </div>
                </BrandedPage>}
            </div>
        </Root>
    );
})

export default RegistrationPage;
