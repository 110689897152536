export const DBConfig = {
  name: 'PFMDial',
  version: 1,
  objectStoresMeta: [
    {
      store: 'scores',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'timestamp', keypath: 'timestamp', options: { unique: true } },
        { name: 'registrationId', keypath: 'registrationId', options: { unique: false } },
        { name: 'sessionId', keypath: 'sessionId', options: { unique: false } },
        { name: 'score', keypath: 'score', options: { unique: false } }
      ]
    }
  ]
};