import React from 'react';

import './DialSkins.css';

export const BasicDial = (props) => {

    return (
        <div>
            <div className="dial-top" style={{
                backgroundColor: props.color
            }}>
                <div className="dial-knob-overlay"></div>
                <div className="dial-knob" style={{
                    backgroundColor: props.color
                }}>
                    <div className="dial-light"></div>
                </div>
            </div>
            <div className="dial-bottom"></div>
        </div>
    );
}

export const ASIDial = (props) => {

    return (
        <div className="asi-dial">
            {/* <div className="asi-knob-overlay"></div> */}
            <div className="asi-wrap">
                <div className="asi-knob">
                    <div className="asi-knob-overlay"></div>
                    <div className="asi-needle" style={{
                        backgroundColor: props.color
                    }}>
                        <div className="asi-light"></div>
                    </div>
                    <div className="asi-border" style={{
                        backgroundColor: props.color
                    }}>
                        <div className="asi-base" style={{
                        backgroundColor: props.color
                    }}></div>
                    </div>
                </div>
            </div>
            <div className="asi-top" style={{
                backgroundColor: props.color
            }}></div>
            <div className='asi-bottom' style={{
                backgroundColor: props.color
            }}></div>
            {/* </div> */}
            <div className="asi-space"></div>

        </div>
    );
}