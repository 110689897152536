import React, { useState, createContext } from 'react'

export const AspectRatio = createContext({
  ratio: null,
  setRatio: () => { }
});

export const AspectRatioProvider = (props) => {
  const [ratio, setRatio] = useState(null)

  return (
    <AspectRatio.Provider value={{ ratio, setRatio }}>
      {props.children}
    </AspectRatio.Provider>
  )
}