export const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

// export const isBrowser = (u) => {
//   for (let i = 0; i < navigator.plugins.length; i++) {
//     if (navigator.plugins[i].name != null && navigator.plugins[i].name.substr(0, u.length) === u) {
//       return true;
//     }
//   }
//   return false;
// }

const isBrowser = (u) => {
    if (navigator.userAgentData) {
        for (let brand_version_pair of navigator.userAgentData.brands) {
            if (brand_version_pair.brand.substr(0, u.length) === u) {
                return true;
            }
        }
        return false;
    }
    return false;
}

export const browserCheck = async (uaName) => {
    const isBrave = (navigator.brave && await navigator.brave.isBrave() || false)
    const browsers = {
        Brave: isBrave,
        Firefox: !!window.InstallTrigger,
        Safari: !!window.ApplePaySession || /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || window.safari.pushNotification),
        Opera: !!window.opr || !!window.opera || isBrowser("Opera"),
        Edge: isBrowser("GREASE") || isBrowser("Microsoft Edg"),
        Chrome: isBrowser("Google Chrome"),
        Chromium: isBrowser("Chromium"),
        // Chrome: !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime),
    }
    const detectedBrowser = Object.keys(browsers).find(k => !!browsers[k])
    return detectedBrowser || uaName
}

const keySystems = {
    // PlayReady: 'com.microsoft.playready',
    Widevine: 'com.widevine.alpha',
    PlayReady: 'com.microsoft.playready',
    // clearkey: ['webkit-org.w3.clearkey', 'org.w3.clearkey'],
    // primetime: ['com.adobe.primetime', 'com.adobe.access'],
    FairPlay: 'com.apple.fps.1_0'
};

export const supportedEncryptedMediaExtensions = async (testVideoElement = document.createElement("video")) => {
    if (window.navigator.requestMediaKeySystemAccess) {
        if (typeof window.navigator.requestMediaKeySystemAccess === 'function') {
            console.log('found default EME');
            const isKeySystemSupported = async (keySystem) => {
                const config = [{
                    initDataTypes: ['cenc'],
                    audioCapabilities: [{
                        contentType: "audio/mp4;codecs=\"mp4a.40.2\""
                    }],
                    videoCapabilities: [{
                        contentType: "video/mp4;codecs=\"avc1.42E01E\""
                    }]
                }];
                try {
                    console.log('CHECKING FOR', keySystems[keySystem])
                    await window.navigator.requestMediaKeySystemAccess(keySystems[keySystem], config)
                    return keySystem
                } catch (ex) {
                    return null
                }
            }
            const supportedSystems = await Promise.all(Object.keys(keySystems).map(isKeySystemSupported))
            return supportedSystems.filter(x => !!x)
        }
    } else if (window.MSMediaKeys) {
        if (typeof window.MSMediaKeys === 'function') {
            console.log('found MS-EME');
            const supportedSystems = Object.keys(keySystems).filter(keysys => window.MSMediaKeys.isTypeSupported(keySystems[keysys]))
            return supportedSystems
        }
    } else if (testVideoElement.webkitGenerateKeyRequest) {
        if (typeof testVideoElement.webkitGenerateKeyRequest === 'function') {
            console.log('found WebKit EME');
            const supportedSystems = Object.keys(keySystems).filter(keysys => testVideoElement.canPlayType('video/mp4', keySystems[keysys]))
            return supportedSystems
        }
    } else {
        console.log('no supported EME implementation found');
        return []
    }
}