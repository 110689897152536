import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PREFIX = 'CompleteMessage';

const classes = {
    root: `${PREFIX}-root`,
    title: `${PREFIX}-title`,
    body: `${PREFIX}-body`,
    spacing: `${PREFIX}-spacing`,
    bold: `${PREFIX}-bold`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flex: 1,
    // width: '100%',
    // height: '100%'
    [`& .${classes.title}`]: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1rem'
    },
    [`& .${classes.body}`]: {
        textAlign: 'center',
        flex: 2,
        padding: '2rem'
        // flexBasis: '40%'
    },
    [`& .${classes.spacing}`]: {
        marginBottom: '1rem'
    },
    [`& .${classes.bold}`]: {
        fontWeight: '500'
    }
});

const CompleteMessage = React.memo(({ loading, approved, canceled, checkInTime }) => {

    const { t } = useTranslation('self_tech_check_step_6');
    const title = approved ? t('tech_check_complete') : canceled ? t('registration_cancelled') : t('thank_you')
    const checkInString = new Date(checkInTime).toLocaleString('en-us', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hourCycle: 'h12', timeZoneName: 'short' })

    return (
        <Root>
            {loading ? <CircularProgress /> : <>
                <Typography variant="h4" className={classes.title}>{title}</Typography>
                <div className={classes.body}>
                    {approved ? <>
                        <Typography variant="body1" className={classes.spacing}>{t('approved_see_you_on')}<br /><span className={classes.bold}>{checkInString}</span>.</Typography>
                        <Typography variant="body1" className={classes.spacing}>{t('separate_conf_email')}</Typography>
                    </> : canceled ? <>
                        <Typography variant="body1" className={classes.spacing}>{t('sorry_youre_unable')}</Typography>
                    </> : <>
                        <Typography variant="body1" className={classes.spacing}>{t('event_is_full')}</Typography>
                    </>}
                    <Typography variant="body1" className={classes.spacing}>{t('reach_out_to_us')} <a href="mailto:onlineevents@previewfreemovies.com">onlineevents@previewfreemovies.com</a>.</Typography>
                    <Typography variant="body1" className={classes.spacing}>{t('window_can_close')}</Typography>
                </div>
            </>}
        </Root>
    );
})

export default CompleteMessage;