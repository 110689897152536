import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'CheckInstructions';

const classes = {
    root: `${PREFIX}-root`,
    title: `${PREFIX}-title`,
    body: `${PREFIX}-body`,
    spacing: `${PREFIX}-spacing`
};

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flex: 1,
    // width: '100%',
    // height: '100%'
    [`& .${classes.title}`]: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-end',
        padding: '1rem'
    },
    [`& .${classes.body}`]: {
        textAlign: 'center',
        flex: 2,
        padding: '2rem'
        // flexBasis: '40%'
    },
    [`& .${classes.spacing}`]: {
        marginBottom: '1rem'
    }
});

const renderMessage = (message) => {
    if (message instanceof Function) {
        const Component = message
        return <Component />
    } else {
        return message || "\u00a0"
    }
}

const CheckInstructions = React.memo(({ title, instructions = [] }) => {


    return (
        <Root>
            <Typography variant="h4" className={classes.title}>{title}</Typography>
            {Array.isArray(instructions) ? <div className={classes.body}>
                {instructions.map((inst, i) => <Typography key={`instructions${i}`} variant="body1" className={classes.spacing}>{renderMessage(inst)}</Typography>)}
            </div> : <Typography variant="body1" className={classes.body}>
                {renderMessage(instructions)}
            </Typography>}
        </Root>
    );
})

export default CheckInstructions;