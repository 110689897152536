import React, { useRef, useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/lab/Alert';
import { useLocalStorage } from './hooks';
import { Tooltip, Button, CircularProgress } from '@mui/material';
import * as workerTimers from 'worker-timers';
import { useTranslation } from 'react-i18next';

const PREFIX = 'EmojiFeedbackDial';

const classes = {
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    emojiDial: `${PREFIX}-emojiDial`,
    emojiIcons: `${PREFIX}-emojiIcons`,
    emojiIcon: `${PREFIX}-emojiIcon`,
    button: `${PREFIX}-button`,
    tuneoutButton: `${PREFIX}-tuneoutButton`,
    alert: `${PREFIX}-alert`,
    scatterEmoji: `${PREFIX}-scatterEmoji`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ratio}) => ({
    [`& .${classes.root}`]: {
        // position: 'absolute',
        // top: 0,
        // width: '100vw',
        // height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1001,
        overflow: 'hidden'
    },
    [`& .${classes.container}`]: {
        // position: 'fixed',
        bottom: 0,
        width: '100%',
        // height: ratio ? `${ratio * 100}vw` : '100%',
        // flex: 1,
        minHeight: '100px',
        maxHeight: ratio ? `calc((100vh - ${ratio * 45}vw) / 2)` : '100%',
        // maxHeight: '100%',
        // maxWidth: ratio ? `${1/ratio * 100}vh` : '100%',
        // margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        // opacity: 0.45,
        filter: 'brightness(1.75) drop-shadow(0px 0px 1px white)',
        border: '1px solid transparent',
        // paddingBottom: '14px',
        boxSizing: 'border-box',
        '@media (min-width: 1024px)': {
            marginBottom: '60px'
        },
        '@media (min-width: 2048px)': {
            marginBottom: '120px'
        }
    },
    [`& .${classes.emojiDial}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    [`& .${classes.emojiIcons}`]: {
        display: 'inherit',
        fontFamily: 'Noto Color Emoji',
        fontSize: 'calc(15px + (75 - 15) * ((100vw - 150px) / (2400 - 150)))',
        filter: 'brightness(.6)',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        '@media (min-width: 1280px)': {
            fontSize: 'calc(15px + (75 - 15) * ((100vw - 150px) / (2400 - 150)) - 10px)'
        },
        '@media (min-width: 2048px)': {
            fontSize: 'calc(15px + (75 - 15) * ((100vw - 150px) / (2400 - 150)) - 20px)'
        }
    },
    [`& .${classes.emojiIcon}`]: {
        width: '100%',
        minWidth: 20,
        maxWidth: '10em',
        fontFamily: 'Noto Color Emoji',
        margin: '1vw',
        cursor: 'pointer',
    },
    [`& .${classes.button}`]: {
        margin: '0 5px 0 5px',
        '&.MuiButton-contained.Mui-disabled': {
            color: 'rgba(255,255,255, .2) !important'
        }
    },
    [`& .${classes.tuneoutButton}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
        marginTop: '5px'
    },
    [`& .${classes.alert}`]: {
        position: 'absolute',
        top: 0,
        padding: '10px',
        transform: 'translateY(-100%)'
    },
    [`& .${classes.scatterEmoji}`]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

// function pop(e, emoji, inputTime) {
//     let amount = 30;
//     let rect = e.target.getBoundingClientRect();
//     for (let i = 0; i < amount; i++) {
//         createParticle(rect, emoji, inputTime);
//     }

// }
// function createParticle(rect, emoji, inputTime) {
//     const particle = document.createElement('span');
//     document.getElementById(`scatter-emoji-${emoji.tooltip}`).appendChild(particle);
//     let width = Math.floor(Math.random() * 30 + 8);
//     let height = width;
//     let destinationX = (Math.random() - 0.5) * (rect.width * 2);
//     let destinationY = (Math.random() - 0.5) * (rect.width * 2);

//     particle.innerHTML = emoji.emoji;
//     particle.style.fontSize = `${Math.random() * 24 + 10}px`;
//     width = height = 'auto';

//     particle.style.width = `${width}px`;
//     particle.style.height = `${height}px`;
//     particle.style.position = 'absolute';
//     particle.style.zIndex = 100;

//     const animation = particle.animate([
//         {
//             transform: `translate(${rect.width / 2}px, ${rect.height / 2}px) `,//`translate(-50%, -50%) translate(${x}px, ${y}px) rotate(180deg)`,
//             opacity: 1
//         },
//         {
//             transform: `translate(${destinationX}px, ${destinationY}px)`,//`translate(-50%, -50%) translate(${x + destinationX}px, ${y + destinationY}px) rotate(340deg)`,
//             opacity: 0
//         }
//     ], {
//         duration: inputTime * 1000,
//         easing: 'cubic-bezier(0, .9, .57, 1)',
//         iterations: 1
//     });

//     animation.addEventListener("finish", () => {
//         document.getElementById(`scatter-emoji-${emoji.tooltip}`).removeChild(particle);
//     });

// }

const EmojiFeedbackDial = React.memo(({ sessionId, userId, viewRatio, onScoreChange, symbolOptions, inputTime, techCheck, includeTuneOut, includeShoutOuts, includeBreaks, maxBreaks, breakCount, setBreakCount, inactiveMessage, inactiveInterval, inactiveReminder, show, sendData, videoIndex }) => {

    const { t } = useTranslation('buttons');
    const onChange = useRef();
    const timerRef = useRef();
    const [buttonPress, setButtonPress] = useLocalStorage(`tuneinout${sessionId}-${videoIndex || 0}-${techCheck ? '-TC' : ''}`, undefined);
    const [lastBtn, setLastBtn] = useState();

    const [disableClick, setDisableClick] = useState(false);
    const [emojiSelected, setEmojiSelected] = useState();
    const [inactive, setInactive] = useState(false);
    const inactivityTimeout = useRef();

    const activityHandler = useCallback(async () => {
        console.log('CLEARING INACTIVITY')
        setInactive(false)
        if (inactivityTimeout.current) workerTimers.clearTimeout(inactivityTimeout.current)
    }, [setInactive])

    const inactivityHandler = useCallback(() => {
        console.log('SETTING INACTIVITY')
        inactivityTimeout.current = workerTimers.setTimeout(() => {
            setInactive(true)
        }, inactiveInterval * 1000)
    }, [inactiveInterval])

    useEffect(() => {
        return () => {
            if (inactivityTimeout.current) workerTimers.clearTimeout(inactivityTimeout.current)
        }
    }, [])

    useEffect(() => {
        if (show && (sendData || techCheck) && inactiveReminder) {
            inactivityHandler()
        } else {
            activityHandler()
        }
    }, [show, sendData, techCheck, inactiveReminder, activityHandler, inactivityHandler])

    onChange.current = onScoreChange

    useEffect(() => {
        onChange.current = onScoreChange;
    }, [onScoreChange])


    useEffect(() => {
        if (sendData && onChange.current) {
            onChange.current({
                button: lastBtn,
                timestamp: Date.now(),
                registrationId: `${userId}`,
                sessionId: `${sessionId}`,
            });
            setLastBtn()
        }
    }, [sendData, sessionId, userId, lastBtn, setLastBtn])

    const onEmojiClicked = useCallback((e, emoji) => {
        if (!disableClick && sendData && onChange.current) {
            setDisableClick(true);
            onChange.current({
                symbol: emoji.emoji,
                timestamp: Date.now(),
                registrationId: `${userId}`,
                sessionId: `${sessionId}`,
            });
            activityHandler().then(inactiveReminder ? inactivityHandler : () => { })
            // buttonRef.current = buttonPress
            setEmojiSelected(emoji.emoji);
            // pop(e, emoji, inputTime);
            timerRef.current = workerTimers.setTimeout(() => {
                timerRef.current = null
                setDisableClick(false);
                setEmojiSelected();
            }, 1 * 1000)
        }
    }, [disableClick, sendData, setDisableClick, userId, sessionId, activityHandler, inactiveReminder, inactivityHandler, setEmojiSelected, setEmojiSelected]);

    const handleTuneOut = useCallback((event) => {
        event.currentTarget.blur()
        if ((sendData || techCheck) && buttonPress !== 'R') {
            setButtonPress('R')
            setLastBtn('R');
        }
    }, [sendData, techCheck, buttonPress, setButtonPress])

    const handleShoutOut = useCallback((event) => {
        event.currentTarget.blur();
        setLastBtn('S');
    }, [setLastBtn])

    const handleBreak = useCallback((event) => {
        event.currentTarget.blur();
        setLastBtn('B-S');
        setBreakCount(prev => prev++);
    }, [setLastBtn])


    return (
        (<Root ratio={viewRatio}>
            <div className={classes.container}>
                <div className={classes.emojiDial}>
                    <div className={classes.emojiIcons}>
                        {symbolOptions?.map(i => <div className={classes.scatterEmoji} id={`scatter-emoji-${i.tooltip}`}>
                            {emojiSelected === i.emoji ? <CircularProgress size={'calc(40px + (75 - 15) * ((100vw - 150px) / (2400 - 150)))'} style={{ position: 'absolute', zIndex: '1', color: 'white' }} /> : <></>}
                            <Tooltip title={i.tooltip}>
                                <div className={classes.emojiIcon} style={emojiSelected === i.emoji || !disableClick ? {} : { filter: 'brightness(.6) grayscale(.5)' }} onClick={(e) => onEmojiClicked(e, i)}>
                                    {i.emoji}
                                </div>
                            </Tooltip></div>)}
                    </div>
                    {(includeTuneOut || includeShoutOuts || includeBreaks) && <div className={classes.tuneoutButton}>
                        {includeTuneOut && <Button className={classes.button} variant="contained" size="small" color="primary" disabled={!((sendData && buttonPress !== 'R') || techCheck)} style={{
                            border: `1px solid rgba(255,255,255,${buttonPress === 'R' ? '0.85' : '0.15'})`,
                            // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                            backgroundColor: '#9d3b3a',
                            // filter: !(sendData || techCheck) ? 'grayscale(1)' : `contrast(${buttonPress === 'R' ? '2.5' : '1'}) grayscale(${buttonPress === 'R' ? '0' : '0.35'})`
                        }} onClick={handleTuneOut}>{t('TUNE_OUT_button')}</Button>}
                        {includeBreaks && <Button className={classes.button} variant="contained" size="small" color="primary" disabled={!(breakCount < maxBreaks)} style={{
                            border: `1px solid rgba(255,255,255,${!(breakCount < maxBreaks) ? '0.85' : '0.15'})`,
                            // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                            backgroundColor: 'rgba(255, 255, 0, 0.5)',
                            // filter: `contrast(${!(breakCount < maxBreaks) ? '2.5' : '1'}) grayscale(${!(breakCount < maxBreaks) ? '0' : '0.35'})`
                        }} onClick={handleBreak}>{t('BREAK_btn')}</Button>}
                        {includeShoutOuts && <Button className={classes.button} variant="contained" size="small" color="primary" style={{
                            border: `1px solid rgba(255,255,255, 0.15)`,
                            // backgroundColor: buttonPress === 'R' ? '#E15554' : '#9d3b3a',
                            backgroundColor: 'rgba(59, 175, 44, 0.42)',
                            // filter: `contrast(${buttonPress === 'R' ? '2.5' : '1'}) grayscale(${buttonPress === 'R' ? '0' : '0.35'})`
                        }} onClick={handleShoutOut}>{t('COMMENT_btn')}</Button>}
                    </div>}
                </div>
                <div className={classes.alert}>
                    {/* <Snackbar open={inactive}> */}
                    {inactive && <MuiAlert elevation={0} variant="filled" severity="error">
                        {inactiveMessage || t('continue_clicking_emoji')}
                    </MuiAlert>}
                    {/* </Snackbar> */}
                </div>
            </div>
        </Root>)
    );
})

export default EmojiFeedbackDial;
