import React from 'react';
// import { useState, useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import { Plus, DoublePlus, Minus, DoubleMinus } from './Shapes';
import './Lights.css';

export const MeterLight = React.memo((props) => {
    // console.log('light render')

    const r = props.notch ? -36 : -46;
    const h = props.notch ? 2 : 4;

    return (
        <div className="meter" style={{
            height: `${h}%`,
            width: `${h * 2}%`,
            backgroundColor: props.visible ? props.backgroundColor : '#333',
            boxShadow: `inset 0 0 3px rgba(0 ,0, 0, 0.15), rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset ${props.visible ? props.insetColor : '#444'} 0 -1px 9px, ${props.visible ? props.backgroundColor : '#333'} 0 0px 4px${props.visible ? `, ${props.glowColor} 0 2px 40px` : ''}`,
            top: `calc(${50.5 + (r * Math.sin(props.rotation * Math.PI / 180)) - h}%)`,
            left: `calc(${50.5 + (r * Math.cos(props.rotation * Math.PI / 180)) - h}%)`,
            transform: `translateZ(1px) rotate(${props.rotation}deg)`,
            // opacity: `${props.visible ? 1 : 0}`
        }}></div>
    );
});

export const ShapeLight = React.memo((props) => {
    // console.log('shape render')

    // const initRef = useRef(null);

    if (!props.double) {
        return (
            <div className="shape" style={{
                transform: `rotate(${-(180 - props.maxAngle) / 2}deg)`,
                top: `calc(${50 + (-46 * Math.sin(props.rotation * Math.PI / 180)) - 6}%)`,
                left: `calc(${50 + (-46 * Math.cos(props.rotation * Math.PI / 180)) - 6}%)`,
            }}>
                {props.type === "+" && <Plus highlight={props.highlight} insetColor={props.insetColors} style={{
                    // top: '10px',
                    // position: 'absolute'
                    fill: props.show && props.visible ? props.backgroundColor : '#444',
                    // fill: props.visible ? props.backgroundColor : props.clearColor,
                    // opacity: `${props.show && props.visible ? 1 : 0}`,
                    stroke: props.insetColor,
                    // filter: `drop-shadow( 0 0 4px ${props.backgroundColor})
                    //   drop-shadow( 0 2px 40px ${props.glowColor})`
                    // stroke: props.visible ? props.insetColor : 'transparent',
                    // filter: props.visible ?
                    //   `drop-shadow( 0 0 4px ${props.backgroundColor})
                    //   drop-shadow( 0 2px 40px ${props.glowColor})` : 'none'
                }} />
                }
                {props.type === "-" && <Minus highlight={props.highlight} insetColor={props.insetColors} style={{
                    // top: '10px',
                    // position: 'absolute'
                    fill: props.show && props.visible ? props.backgroundColor : '#444',
                    // fill: props.visible ? props.backgroundColor : props.clearColor,
                    // opacity: `${props.show && props.visible ? 1 : 0}`,
                    stroke: props.insetColor,
                    // filter: `drop-shadow( 0 0 4px ${props.backgroundColor})
                    //   drop-shadow( 0 2px 40px ${props.glowColor})`
                    // stroke: props.visible ? props.insetColor : 'transparent',
                    // filter: props.visible ?
                    //   `drop-shadow( 0 0 4px ${props.backgroundColor})
                    //   drop-shadow( 0 2px 40px ${props.glowColor})` : 'none'
                }} />
                }
                {/* <Plus insetColor={props.insetColors} style={{
            // top: '10px',
            // position: 'absolute'
            fill: props.visible ? props.backgroundColor : props.clearColor,
            // opacity: `${props.visible ? 1 : 0}`,
            filter: props.visible ?
              `drop-shadow( 0 0 4px ${props.backgroundColor})
              drop-shadow( 0 2px 40px ${props.glowColor})` : 'none'
          }}/> */}
            </div>
        );
    } else {
        return (
            <div className="double" style={{
                transform: `rotate(${-(180 - props.maxAngle) / 2}deg)`,
                top: `calc(${50 + (-46 * Math.sin(props.rotation * Math.PI / 180)) - 6}% - 2vw)`,
                left: `calc(${50 + (-46 * Math.cos(props.rotation * Math.PI / 180)) - 6}% - 2vw)`,
            }}>
                {props.type === "+" && <DoublePlus highlight={props.highlight} insetColor={props.insetColors} style={{
                    // top: '10px',
                    // position: 'absolute'
                    fill: props.show && props.visible ? props.backgroundColor : '#444',
                    // fill: props.visible ? props.backgroundColor : props.clearColor,
                    // opacity: `${props.show && props.visible ? 1 : 0}`,
                    stroke: props.insetColor,
                    // filter: `drop-shadow( 0 0 4px ${props.backgroundColor})
                    //   drop-shadow( 0 2px 40px ${props.glowColor})`
                    // stroke: props.visible ? props.insetColor : 'transparent',
                    // filter: props.visible ?
                    //   `drop-shadow( 0 0 4px ${props.backgroundColor})
                    //   drop-shadow( 0 2px 40px ${props.glowColor})` : 'none'
                }} />
                }
                {props.type === "-" && <DoubleMinus highlight={props.highlight} insetColor={props.insetColors} style={{
                    // top: '10px',
                    // position: 'absolute'
                    fill: props.show && props.visible ? props.backgroundColor : '#444',
                    // fill: props.visible ? props.backgroundColor : props.clearColor,
                    // opacity: `${props.show && props.visible ? 1 : 0}`,
                    stroke: props.insetColor,
                    // stroke: props.show && props.visible ? props.insetColor : '#aaa',
                    // filter: `drop-shadow( 0 0 4px ${props.backgroundColor})
                    //   drop-shadow( 0 2px 40px ${props.glowColor})`
                    // stroke: props.visible ? props.insetColor : 'transparent',
                    // filter: props.visible ?
                    //   `drop-shadow( 0 0 4px ${props.backgroundColor})
                    //   drop-shadow( 0 2px 40px ${props.glowColor})` : 'none'
                }} />
                }
            </div>
        );
    }
});