import { useState, useRef, useEffect, useCallback } from 'react';
import { SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
// import AWS, { KinesisVideo } from 'aws-sdk'

const getUrl = async (url) => {
    let convertedUrl;

    if (typeof url === 'function') {
        convertedUrl = await url();
    } else {
        convertedUrl = url;
    }

    return convertedUrl
}

const getHttpOpts = async ({httpOpts}) => {
    let options;

    if (typeof httpOpts === 'function') {
        options = await httpOpts();
    } else {
        options = httpOpts;
    }

    return options
}

export const useKinesisWebRTC = (url, role, region, options) => {
    // const [connected, setConnected] = useState(false)
    const [lastMessage, setLastMessage] = useState(null)
    const [dataChannelConnected, setDataChannelConnected] = useState(false)

    const urlRef = useRef()
    const optionsRef = useRef()
    const roleRef = useRef()
    const regionRef = useRef()
    const signalingClient = useRef()
    const peerConnection = useRef({})
    const dataChannel = useRef({})
    const peerConnected = useRef(false)
    const signalingConnected = useRef(false)

    roleRef.current = (['MASTER', 'VIEWER'].includes(role) && role) || 'VIEWER'
    regionRef.current = region
    optionsRef.current = options

    const setup = useCallback(async (rtcUrl) => {

        urlRef.current = await getUrl(rtcUrl)

        const httpOpts = await getHttpOpts(optionsRef.current)

        const res = await fetch(urlRef.current, {
            method: 'GET',
            mode: 'cors',
            ...httpOpts
        })

        const { signedURL, channelARN, iceServers } = await res.json()

        if (roleRef.current === 'VIEWER') {
            peerConnection.current = new RTCPeerConnection({ iceServers });
            dataChannel.current = peerConnection.current.createDataChannel('dialScoreChannel');

            peerConnection.current.ondatachannel = (event) => {
                console.log('data channel: ', event.channel)
                const channel = event.channel
                channel.onerror = (e) => {
                    console.log('data channel error', e)
                }
                channel.onmessage = (event) => {
                    console.log('got message!', event.data)
                    let message;
                    try {
                        message = JSON.parse(event.data)
                    } catch (e) {
                        message = event.data
                    }
                    setLastMessage(message)
                };
                // channel.onmessage = onmessage;
                channel.onopen = (event) => {
                    console.log('data channel opened')
                    peerConnected.current = true
                    setDataChannelConnected(true)
                    optionsRef.current.onOpen && optionsRef.current.onOpen(event)
                };
                channel.onclose = () => {
                    console.log('data channel is closed')
                    peerConnected.current = false
                    setDataChannelConnected(false)
                    if (optionsRef.current.shouldReconnect && optionsRef.current.shouldReconnect(event)) {
                        console.log('attempting reconnection')
                        setup(rtcUrl)
                    }
                };
            };

            peerConnection.current.addEventListener('icecandidate', ({ candidate }) => {
                if (candidate) {
                    console.log('[VIEWER] Generated ICE candidate');

                    console.log('[VIEWER] Sending ICE candidate');
                    signalingClient.current.sendIceCandidate(candidate);

                } else {
                    console.log('[VIEWER] All ICE candidates have been generated');

                }
            });

            peerConnection.current.addEventListener('iceconnectionstatechange', (event) => {
                if (peerConnection.current.iceConnectionState === "disconnected" || peerConnection.current.iceConnectionState === "closed") {
                    console.log('ice connection state is closed/disconnected')
                    peerConnected.current = false
                    setDataChannelConnected(false)
                    if (optionsRef.current.shouldReconnect && optionsRef.current.shouldReconnect(event)) {
                        console.log('attempting reconnection')
                        setup(rtcUrl)
                    }
                }
            });
        }

        const requestSigner = {
            getSignedURL: async (x) => {
                return x
            }
        }

        signalingClient.current = new SignalingClient({
            channelARN,
            channelEndpoint: signedURL,
            clientId: roleRef.current === 'VIEWER' ? optionsRef.current.viewerId : undefined,
            role: roleRef.current,
            region: regionRef.current,
            requestSigner,
            // systemClockOffset: kinesisVideoClient.config.systemClockOffset,
        });

        signalingClient.current.on('open', async () => {
            console.log('connected to signaling client')
            signalingConnected.current = true
            if (roleRef.current === 'VIEWER') {
                // Create an SDP offer and send it to the master
                const offer = await peerConnection.current.createOffer({
                    offerToReceiveAudio: false,
                    offerToReceiveVideo: false,
                });
                await peerConnection.current.setLocalDescription(offer);
                signalingClient.current.sendSdpOffer(peerConnection.current.localDescription);
            }
        });


        signalingClient.current.on('iceCandidate', async (candidate, remoteClientId) => {
            console.log('Received ICE candidate');
            const peer = roleRef.current === 'VIEWER' ? peerConnection.current : peerConnection.current[remoteClientId];
            peer.addIceCandidate(candidate);
        });


        signalingClient.current.on('close', (event) => {
            console.log('Disconnected from signaling channel');
            signalingConnected.current = false;
            if (roleRef.current === 'MASTER' && !peerConnected.current && optionsRef.current.shouldReconnect && optionsRef.current.shouldReconnect(event)) {
                console.log('attempting reconnection')
                setup(rtcUrl)
            }
        });

        signalingClient.current.on('error', () => {
            console.error('Signaling client error');
        });

        if (roleRef.current === 'MASTER') {
            signalingClient.current.on('sdpOffer', async (offer, remoteClientId) => {
                console.log('[MASTER] Received SDP offer from client: ' + remoteClientId);

                // Create a new peer connection using the offer from the given client
                const peer = new RTCPeerConnection({ iceServers });
                peerConnection.current[remoteClientId] = peer;

                dataChannel.current[remoteClientId] = peer.createDataChannel('dialScoreChannel');

                peerConnection.current[remoteClientId].ondatachannel = event => {
                    console.log('data channel!', event.channel)

                    const channel = event.channel
                    channel.onerror = (e) => {
                        console.log('data channel error', e)
                    }
                    channel.onmessage = (event) => {
                        console.log('got message!', event.data)
                        let message;
                        try {
                            message = JSON.parse(event.data)
                        } catch (e) {
                            message = event.data
                        }
                        setLastMessage(message)
                    };
                    channel.onopen = (event) => {
                        console.log('data channel opened')
                        peerConnected.current = false
                        setDataChannelConnected(true)
                        optionsRef.current.onOpen && optionsRef.current.onOpen(event)
                    };
                    channel.onclose = () => {
                        console.log('data channel is closed')
                        peerConnected.current = false
                        setDataChannelConnected(false)
                        if (!signalingConnected.current && optionsRef.current.shouldReconnect && optionsRef.current.shouldReconnect(event)) {
                            console.log('attempting reconnection')
                            setup(rtcUrl)
                        }
                    };
                };

                // Send any ICE candidates to the other peer
                peerConnection.current[remoteClientId].addEventListener('icecandidate', ({ candidate }) => {
                    if (candidate) {
                        console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId);

                        console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
                        signalingClient.current.sendIceCandidate(candidate, remoteClientId);

                    } else {
                        console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);

                    }
                });

                peerConnection.current[remoteClientId].addEventListener('iceconnectionstatechange', (event) => {
                    if (peerConnection.current[remoteClientId].iceConnectionState === "disconnected" || peerConnection.current[remoteClientId].iceConnectionState === "closed") {
                        console.log('ice connection state is closed/disconnected')
                        peerConnected.current = false
                        setDataChannelConnected(false)
                        if (optionsRef.current.shouldReconnect && optionsRef.current.shouldReconnect(event)) {
                            console.log('attempting reconnection')
                            setup(rtcUrl)
                        }
                    }
                });

                await peerConnection.current[remoteClientId].setRemoteDescription(offer);

                console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);

                await peerConnection.current[remoteClientId].setLocalDescription(
                    await peerConnection.current[remoteClientId].createAnswer({
                        offerToReceiveAudio: false,
                        offerToReceiveVideo: false,
                    }),
                );

                console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
                signalingClient.current.sendSdpAnswer(peerConnection.current[remoteClientId].localDescription, remoteClientId);

                console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
            });
        }

        if (roleRef.current === 'VIEWER') {
            signalingClient.current.on('sdpAnswer', async answer => {
                console.log('got answer')
                await peerConnection.current.setRemoteDescription(answer);
            });
        }


        console.log('Starting connection...');
        signalingClient.current.open();
    }, [])

    useEffect(() => {
        const unloadHandler = (event) => {
            console.log('gracefully closing peer connections')
            if (roleRef.current === 'MASTER') {
                Object.keys(peerConnection.current).forEach((peer) => {
                    peerConnection.current[peer].close()
                })
            } else {
                peerConnection.current.close()
            }
            window.removeEventListener(event.type, unloadHandler);
        }
        window.addEventListener('unload', unloadHandler)
    }, []);

    const sendMessage = useCallback((message) => {
        console.log('sending message: ', message)
        if (roleRef.current === 'MASTER') {
            Object.keys(dataChannel.current).forEach(clientId => {
                try {
                    dataChannel.current[clientId].send(message);
                } catch (e) {
                    console.error('[MASTER] Send DataChannel: ', e.toString());
                }
            });
        } else {
            try {
                dataChannel.current.send(message);
            } catch (e) {
                console.error('[VIEWER] Send DataChannel: ', e.toString());
                try {
                    setup(url)
                } catch (e) {
                    console.log('CANNOT SETUP')
                }
            }
        }
    }, [url, setup])

    useEffect(() => {
        setup(url);
    }, [url, setup])

    return {
        sendMessage,
        lastMessage,
        dataChannelConnected
    }

}